import React from 'react';
import { A } from '../views/components'

export const CONTACTS = {
    LINKEDIN: {
        URL: 'https://www.linkedin.com/in/oielbanna/',
        LOGO: { 
            __html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>' 
        },
    },
    TWITTER: {
        URL: 'https://twitter.com/Omarcodess',
        LOGO: {
            __html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>'
        },
    },
    GITHUB: {
        URL: 'https://github.com/oielbanna/',
        LOGO: {
            __html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>'
        },
    },
    MEDIUM: {
        URL: 'https://medium.com/@oielbanna',
        LOGO: {
            __html: '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z"/></svg>'
        },
    },
    CODESANDBOX: {
        URL: 'https://codesandbox.io/u/oielbanna',
        LOGO: {
            __html: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="24px" height="25px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 296"><path d="M115.498 261.088v-106.61L23.814 101.73v60.773l41.996 24.347v45.7l49.688 28.54zm23.814.627l50.605-29.151V185.78l42.269-24.495v-60.011l-92.874 53.621v106.82zm80.66-180.887l-48.817-28.289l-42.863 24.872l-43.188-24.897l-49.252 28.667l91.914 52.882l92.206-53.235zM0 222.212V74.495L127.987 0L256 74.182v147.797l-128.016 73.744L0 222.212z" /></svg>'
        },
    }
}

const SHORTEST = 'shortest';
const MID = 'mid';
const LONGEST = 'longest';
export const BIO_LENGTHS = [SHORTEST, MID, LONGEST];

const SHORTEST_COMPONENT = () => {
    return <p><i><strong>Omar</strong> is trying his very best.</i></p>;
}
const MID_COMPONENT = () => {
    return <p><strong>Omar</strong> likes to make <span className="fun">fun,</span> <span className="shake">interactive</span> things with code.<br /> He gets particularly excited when these things involve working with others to make an even <span style={{ fontWeight: 600, fontSize: 20 }}>bigger</span> impact.</p>
}
const LONGEST_COMPONENT = () => {
    return <p><strong>Omar</strong> is a full-stack software developer at <A className="link" href="https://www.capitalone.ca/">Capital One</A> based in Toronto, Canada.<br /> He likes to <A className="link" target="_blank" href={CONTACTS.GITHUB.URL}>code</A> with React/Vue and Spring Boot, but his true passion lies in Software Architecture. So, he set out on a journey to take the <A className="link" href="https://aws.amazon.com/certification/certified-solutions-architect-associate/">AWS Certified Solutions Architect</A> Course - which you can follow along on <A className="link" href={CONTACTS.TWITTER.URL}>Twitter.</A> He also enjoys writing <A className="link" target="_blank" href={CONTACTS.MEDIUM.URL}>Medium</A> articles sometimes - it helps him <q><i>explore a new medium.</i></q><small> (get it?)</small></p>
}
export const ABOUT = {
    [SHORTEST]: <SHORTEST_COMPONENT />,
    [MID]: <MID_COMPONENT />,
    [LONGEST]: <LONGEST_COMPONENT />,
};

const REACT = 'react';
const VUE = 'vue';
const EXPRESS = 'express.js';
const SOCKET = 'socket.io';
const SCSS = 'scss';
const TS = 'typescript';
const THREE = 'three.js'; // eslint-disable-line

export const PROJECTS = [{
    name: 'Piclette',
    github: 'https://github.com/oielbanna/piclette',
    slug: 'A smart color palette generator',
    description: 'This is going to be a relatively long description. Bullet points maybe?',
    stack: [VUE, SCSS],
}, {
    name: 'Framer Motion',
    github: 'https://github.com/framer/motion/pull/894',
    slug: 'Open Source Contribution',
    description: 'This is going to be a relatively long description. Bullet points maybe?',
    stack: [TS, REACT],
}, {
    name: 'Legends of Andor',
    github: 'https://github.com/oielbanna/legends-of-andor',
    slug: 'Digital board game',
    description: '',
    stack: [TS, EXPRESS, SOCKET, 'phaser.js'],
},
// }, {
//     name: 'React Plane Game',
//     github: 'https://github.com/oielbanna/react-plane-game',
//     slug: 'Threejs game with no score',
//     description: '',
//     preview: require('../assets/plane.gif'),
//     stack: [REACT, THREE],
// }, {
{
    name: 'React-Cursor-Follow',
    github: 'https://github.com/oielbanna/react-cursor-follow',
    slug: 'React cursor follower component',
    description: 'This is going to be a relatively long description. Bullet points maybe?',
    stack: [REACT],
},]

export const JOURNEY = [{
    category: 'education',
    title: 'Computer Science',
    entity: 'McGill University',
    dateRange: 'graduated may \'20',
    description: ['Minor in Psychology and Computer Science concentration', 'GPA 3.3/4.0'],
}, {
    category: 'kinda education',
    title: 'Software Developer Intern',
    entity: 'Nakisa',
    dateRange: 'jan \'19 - may \'20',
    description: [
        // 'Developed a project management dashboard app with VueJS & Spring Boot.',
        // 'Implemented API webhooks to manage 50,000+ JIRA tickets, automate redundant workflow & create a Twilio notification system.',
    ],
}, {
    /*
<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@elsa.majimbo/video/6875407674872188161" data-video-id="6875407674872188161" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@elsa.majimbo" href="https://www.tiktok.com/@elsa.majimbo">@elsa.majimbo</a> <p>When I’m required to participate in anything social</p> <a target="_blank" title="♬ It's a Pandemic - Elsa Majimbo" href="https://www.tiktok.com/music/It's-a-Pandemic-6875407707369704194">♬ It's a Pandemic - Elsa Majimbo</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>
    */
    // https://www.tiktok.com/@elsa.majimbo/video/6875407674872188161?sender_device=pc&sender_web_id=6869749341087794694&is_from_webapp=v2
    category: 'somehow i got a job',
    title: 'Sr. Associate Software Developer',
    entity: 'Capital One',
    dateRange: 'june \'20 - present',
    description: [
        // 'Developing a customer servicing application which modernizes the legacy system and improves data centralization.',
        // 'Building using microfrontends, Vuejs, Nodejs, and AWS.'
    ]
}];

export const STARS_COORDS = [
    "M1103 146c-1 4 0 6 5 9-5 1-9 2-9 7-2-4-5-7-10-6 4-3 4-6 3-10 2 1 7 6 11 0z",
    "M1089 367c-8 0-13 2-14 9l-2-1c-2-6-5-8-10-9-3 0-2-1-1-2l1-1c4-2 5-6 3-11v-2l1-1c5 5 11 6 17 1-3 7-2 13 5 17z",
    "M1086 268c5-4 6-9 3-15 6 3 11 3 15-2-1 6 1 11 8 14-8 1-11 4-12 11-3-6-7-9-14-8z",
    "M1207 165c-7-1-10 2-12 7-2 0-2-1-2-2-1-4-3-6-7-6-1 0-3 0-2-2 5-6 5-6 5-13 5 5 9 5 14 2-2 5-1 10 4 14z",
    "M1123 301c2 2 1 6 3 7 2 2 5 2 8 3-6 3-8 7-7 13-4-4-9-6-15-2 1-3 3-6 2-8l-3-6s5 2 8 0c3-1 4-7 4-7z",
    "M1178 267c-3-4-7-6-11-4 2-4 2-8-1-12 4 1 8 1 10-3 1-1 2-1 2 1 0 4 3 6 8 7-6 2-8 5-8 11z",
    "M1155 239c-4-4-7-6-12-4 3-4 3-8 0-12 4 1 7 1 10-3 0-1 1-1 1 1 0 4 3 6 8 8-5 1-7 4-7 10z",
    "M1125 212l-1 2h-1v-1c0-2 0-2-2-1l-1 1-1 1h-2l1-1 1-1 1-1v-2l-2-2h3l1-1v-1-2l2 2c0 2 0 2 2 1l1-1h1v1l-1 1c-1 1-2 2 0 3l-1 1h-1v1zM1092 337v1h-2v-1-2h-1l-2-1-1-1 1-1h2l2 1 1-1v-3l2 2v1h2v-1l2-1c1 1 0 2-1 3s-1 1 1 2h1l1 1-1 1-1-1c-2 0-3 0-3 2l-1 1-1-1-1-1z",
    "M1123 179c-3 2-5 4-5 7 1 2 0 2-1 1-3-2-5-1-9 0 2-4 2-7-2-10 4 0 7-1 9-4h1c1 4 3 5 7 6z",
    "M1179 191l2 2-1 3h-3l-1-3 3-2z",
    "M1150 176c2-3 3-6 0-9 4 1 6 1 9-3 0 4 1 7 5 8-3 2-6 3-5 7-3-2-5-5-9-3zM1099 212c1-1 2 0 4 1-5 2-7 4-6 9-3-3-7-4-11-2 2-4 2-7-1-11 4 1 7 0 9-4 2 2 2 6 5 7-2 1-3 0-4-1-1 0-1-2-2 0 0 1-3 0-3 2 0 3 3 2 5 4-2-4 3-3 4-5z",
    "M1095 295c-1 3-1 5 2 6-3 1-6 1-6 5-2-3-3-5-6-5 2-2 4-4 3-7 2 1 4 3 7 1z",
    "M1121 232c0 2-1 4 1 5-2 1-4 1-5 4-1-3-1-4-4-4 2-2 3-3 2-5 2 1 4 2 6 0z",
    "M1196 234l1 1-2 2-1-1v-1l2-1z",
    "M1148 294l-1 1v1c0 1-3-5-3 0-1 2-2 1-3 1s-2-1-3 1l2 1h2c1 1 3 1 3 3l-1 3c1 1 2-1 2-2l2 1 1 2 1-2c-1-3 1-3 2-3l2-1-3-1c-1-1-3 0-2-2l1-2v-2c-1-1-2 1-2 1zm3 5v0z",
    "M1148 133c0-1-1-3-3-3-2 1-1 3-1 5h1c1 3-1 3-3 3l-3-1-3-1-3 1c0 2 1 2 2 3l4 1h2c3 1 3 1 1 4l-1 1v1c-1 1-2 2-1 4 1 1 2-2 3-3l2-2 1-1c1-1 2 0 2 1v2c1 1 3 0 2-1 0-4 3-4 5-3h3l2-1-2-2-2-1c-3 0-4-1-1-3 1-1 2-3 1-5-2-1-3 1-3 2l-2 1h-2l-1-2z",
    "M1191 212c0 1 0 3-2 2v1c-2-2-3-4-5-3-1 1 2 3 3 4v1h1c1 2 3 2 2 4h-6c-1 2-1 2 2 2l2 1-1 1-3 3-1 2 4-1 2-1 4-2v1l1 3 1 2 1-2v-1c0-1-1-3 1-3l3 1 2 2 1-2-1-2c-1-4 0-5 3-7h1c-1-5-3-2-5-2l-1 1-3 1c-2-1-1-3-1-4l-1-3-2-2-2 3z",
    "M1155 193l4 1c2-1 3-1 2 2-1 2 0 3 1 5s1 2-1 2-4 0-5 2-2 2-3 0c0-1 0-3-2-3-3-3-3-3 0-5l1-3c0-3 1-2 3-1z",
    "M1099 212l-1 1c-3 1-3 3-2 6l-1 1-1-1c1-2-1-2-2-2-3 0-3-3-4-5l2-1c2 0 4-1 4-3 1 2 2 4 5 4z",
    "M1075 178v1c-3-1-3-4-6-4v2l2 2 2 2c1 2 3 3-1 4 0 2 3 0 3 1l-1 7 3-5 2 1 3 2c1 1 1 2 2 1v-2c0-2-1-2-2-3v-1c-2-1-2-2 0-3s5-2 5-4c-2-4-3-3-5-1-1 1-2 2-3 0 0-2 1-4-1-4s-2 3-3 4z",
    "M1130 269l-1-1-1 1 1 1 1-1z",
    "M1124 257c0 4 7 10 12 11l-1 3h-1l-1 1c-1 0-3 1-2 2h3l1-1 3 1c0 1 0 3 2 3s0-2 1-3l-1-1 1-1h-1l5 1h4c2 0 3-1 2-3l-4-1c-2 0-2-1-1-3h1c1-1 3-2 3-4 0-1 2-3-1-2l-3 1-1 2c-2 1-3 2-3-1v-2c-4-1-3 2-4 4l-1 2-7-5c-2-1-3-4-6-4zm11 16z",
    "M1037 194l-1-1-1-1h2c3 1 2 1 3-2l1-2 1 3-1 2 2-1 2-1h2l-1 1-3 2-1 1 2 1v1h-3l-1 1v1l-1 2-1-2c0-1 1-3-1-4v-1z",
    "M1063 149c2-1 2 0 2 1l1 1h2l-2 2c-2 1 0 2 0 2l1 1c0 2-1 1-1 1-2-1-2-1-3 1l-1 1-1-2h-1l-1-1v-1l2-2-1-2-1-1-1-2 2 1 2 2 1-2z",
    "M1065 252c0-1 2-1 2-3h1l2 1h1v4l-3 1c-1 1 0-1-1-1l-2-2zM1052 322c-1-1 0-2-2-3v-1c1 0 2 0 3-2 1 2 2 2 3 2l-1 3c1 1 0 1-1 1h-2z",
    "M1039 239v3h-1l-1 1 1 1 1 2 1 1 1-1 2-1 1-1-1-1v-1-2h-3l-1-1z",
    "M1064 229c0-1 0-1 0 0h2l-1 1v1l-2 1v-1l-1-1 2-1z",
    "M1056 208h3v2 1l1-1h1v-1l2 2 1 5c2-1 1-2 1-2l-1-4c-1-2-1-2 1-2h2l-1-1h-2v-3l-2 2-2-3h-2v1l2 1h-1l1 1-5 1-1 1h2z",
    "M1062 277zM1062 277c-2 1-4 2-6 1l-3-1c-1 2 0 3 1 4v6l-1 2-1 1h2l5 1 4 4c2 1 1-1 2-2v-4l1-1 5-1c2-1 2-2 0-3s-4-2-5-5v-1c0-1 1-3-1-3-1-1-2 1-3 2z",
    "M1267 20c-4-1-7 0-9 5-1-5-2-9-7-9 4-3 7-5 6-10 3 4 6 4 10 3-1 2-6 7 0 11z",
    "M1046 5c0-7-3-12-9-14l1-1c5-2 8-5 8-10 1-3 2-2 3-1 3 5 7 6 12 4h2l1 1c-5 5-6 11-1 17-7-3-13-2-17 4z",
    "M1144 3c5 5 10 6 16 3-3 6-3 11 2 15-7-1-12 1-14 8-1-8-5-11-11-12 6-3 9-7 7-14z",
    "M1068 110l-1-1v-4c0-3-1-3-3-4-2 0-4-1-4-3 1-4 4-3 7-4l1-4v-1c1-1 0-3 2-3l3 2 1 3c1 2 3 1 5 1h3c1 2-1 3-1 4-2 2-1 5 0 7l1 4-6-2c-2 0-3 0-4 2l-4 3zM877 127l-1-1-1-4c1-3 0-3-3-4-2 0-3-1-4-3 1-4 5-3 7-4l2-4v-1c0-1-1-3 1-3s2 2 3 2l2 3c1 2 3 1 4 1h4c1 2-1 3-1 4-2 2-2 5-1 7 1 1 2 2 1 4l-6-2c-1 0-3 0-4 2l-3 3z",
    "M1248 124c1-7-2-10-7-12 0-2 1-2 2-2 4-1 6-3 6-7 0-1 0-3 2-2 6 4 6 4 12 5-4 4-4 9-1 14-6-2-10-1-14 4z",
    "M1112 40c-2 2-6 1-7 3-2 2-2 4-3 8-3-6-7-9-13-7 4-5 5-9 2-15l8 2c2 0 4-2 6-4 0 0-2 6 0 9 1 3 7 4 7 4z",
    "M1146 95c4-3 6-7 4-12 4 3 8 3 12 0-1 4-1 8 3 10 1 1 0 2-1 2-4-1-6 3-8 7-1-5-4-7-10-7z",
    "M1173 72c5-4 7-7 5-12 4 3 8 3 12 0-1 4-1 7 3 10l-1 1c-5 0-6 3-8 8-2-5-5-8-11-7z",
    "M1201 41h-2v-1h1c2 0 2-1 0-2v-1l-2-1 1-2h1l1 1 1 2h2v-1l2-1v3l1 1h2c1 1 0 1-1 2-2 0-2 0-1 1l1 1v2l-1-1h-1c-1-1-2-3-4-1h-1l1-1-1-1zM1076 9h-1l-1-2h4V6l1-3h1l1 1-1 2-1 2 2 1h2c1 1-1 2-1 2h-1v2c1 1 2 1 1 2s-1 0-2-1-2-1-2 1v1l-1 1-1-1v-2c1-1 1-2-1-2l-1-1 1-1 1-1z",
    "M1233 29c-3-3-5-6-8-5l-1-1c3-3 2-6 0-9 4 1 7 1 10-2 1 4 1 7 4 8v2c-4 0-5 3-5 7z",
    "M1222 96l-2 2-3-1v-3l3-1 2 3z",
    "M1227 33c4 2 7 3 10 0-1 4-1 7 2 10-4 0-6 1-7 5-2-4-3-6-7-6 2-2 5-4 2-9z",
    "M1098 79c4 2 7 3 10 0-1 4-1 7 2 10-4 0-6 1-7 5-2-3-3-6-7-6 2-2 5-4 2-9z",
    "M1201 15l-1 4c-2-4-5-6-9-5 2-4 4-7 2-11 4 2 7 2 10-2-1 5 1 8 5 10-2 2-6 1-7 4-1-1-1-2 1-4 0 0 2 0 0-1l-2-3c-3 0-3 3-4 4 4-1 3 3 5 4z",
    "M1118 12c-3-1-5-1-6 2-1-3-1-6-5-6 3-2 5-3 5-6 2 2 4 4 7 3-2 2-3 4-1 7z",
    "M1181 38c-3-1-4-1-5 1-1-2-1-4-4-5 2-1 4-1 4-4 1 2 3 3 5 2-1 2-2 4 0 6z",
    "M1179 112l-1 2-2-2 1-1h1l1 1z",
    "M1127 111c1-1 4 0 4-2h-4c-1 0-3 1-3-2 1-1 3-3 1-4s-2 2-3 3v1l1-1-1 1-2 1-4-4c-1 2 2 4 2 6l-3 1c-2 0-2 0 0 1h5l-1 4-1 5 1 1 1-1 1-3 1-3c2 0 2 1 3 2 1 2 2 4 5 5l-3-8c-2-1-1-2 0-3zm-8-2l-1 1 1-1z",
    "M1074 60l-4-5c-2-2-2-3 0-3s5 0 4-3c-1-2-3-1-4 0-1 0-2 1-3-1l2-6c1-1 2-2 1-4-2 0-2 1-3 2v1c-2 1-2 2-2 4-1 1-1 0-2-1v-2c-1-1-1-3-4-3v2c0 1 2 3 1 4s-3-1-4-2h-1l-1-1-3 1 2 1 7 5-3 1h-5c-2 1-2 2 0 2l3 1c7 0 7 0 6 8l-1 3c0 1 0 2 2 2 1 0 2-1 1-3l2-7h1l2 6 2 1 1 2c0 2 0 4 2 3l-1-3v-3c1 0 3 2 4 1l-2-3z",
    "M1118 65l-1-1c-1-1 5-3 0-3-2-1-1-2-1-3s1-2-1-3l-1 2-1 2c0 1 0 3-3 3-1-1-2-2-3-1l2 2h1l-1 1-2 2 2 1c3-1 3 1 3 2v2c2-1 1-2 2-3 0-1 0-3 2-2l1 1h3c1-2-1-2-2-2zm-4 3h-1 1z",
    "M1266 65c1 0 4-1 3-3s-3-1-4-1l-1 1c-3 1-3-1-3-3l2-4v-2l-1-3-3 2v4l-1 2c-1 3-1 3-4 1l-1-1h-1c-1-1-2-2-3-1s1 2 2 3l2 2 1 1c1 1 0 2-1 2h-2c0 1 0 3 1 2 5 0 4 3 3 6v2l1 2 2-2 1-2c0-3 1-4 3-1 2 1 3 3 5 1 1-2-1-2-2-3l-1-2v-2l2-1z",
    "M1201 108c-2 0-3 0-3-2v-1c2-1 4-2 3-4-1-1-3 2-4 3h-1l-1 1c-1 0-1 2-3 2v-6c-2-1-2-1-2 2h-1v2l-2-1-2-3-3-1 2 4 1 2 2 3-1 1h-4l-1 2 2 1h1c1 0 3-1 3 1l-2 2-1 2 2 2 2-1c4-1 5-1 6 3l1 1c5-1 2-3 2-5l-1-1-1-4 4-1h2l3-2c0-2-2-2-3-2z",
    "M1220 61v4c0 2 0 4-2 3h-5c-2 2-3 1-3-1 1-2 0-3-2-5-2-1-1-2 1-3l3-1c2-4 2-4 5 0h3c2 0 1 2 0 3z",
    "M1201 15h-2c-1-3-2-3-5-2l-1-1 1-1c2 0 2-1 2-2 0-3 3-3 5-4 1-1 1 1 1 2 0 2 0 4 3 4-3 0-4 2-4 4z",
    "M1235-8h-1c1-3 4-3 4-6h-1-1l-2 2-2 1v1c-2 1-3 3-4-1-2 0 0 2-1 3l-7-1 5 3-1 2-2 3c-1 1-2 1-1 2s2 0 2 0c2 0 2-1 3-2l1-1c1-1 2-1 3 1s1 5 4 5c4-2 2-4 1-5s-2-2 0-3c1 0 4 1 4-2 0-2-3-1-4-2z",
    "M1144 47v-1-1l-1 1 1 1z",
    "M1156 41c-4 0-11 7-11 12l-3-1v-1l-1-1c-1-2-1-3-2-2v7c-1 0-4 0-3 2h5l-1 5-1 4c0 2 1 3 3 2l1-4c1-2 2-2 3-1l1 1 3 3c1 0 3 2 3-1l-2-3-1-1c-1-2-2-3 1-3h2c1-4-2-3-4-4l-2-1 4-7c1-2 4-3 5-6zm-16 11z",
    "M1219-46v-1l1-1 1 1v1c-1 2-1 2 1 3l2 1h-4l1 2 1 2v1h-2l-1-3-1-1-2 2-1-1 1-2-2-1h-1l-1-1 1-1c2 0 3 1 4-1h2z",
    "M1264-20l-1 2h-1v2c-1 1-2 0-2-1-2-2-2-1-3 0l-1 1v-2c1-1 1-1-2-2v-2h1c2 1 2 0 1-1v-2l1 1 2 2 2-2h1l2-1v2l-2 2 2 1z",
    "M1161-18c1 0 1 2 3 2l-1 3v1h-4l-1-3c-1-1 1-1 1-1l2-2zM1091-31c1-1 2 0 3-2l2 3h0l-1 3-4-1v-1-2z",
    "M1133-45l-2 1-3-3 3-3 3 1v1l1 2-1 1h-1z",
    "M1174-44h-3v-1l-1-2-1 2-2 1-1 1h1l1 3h5v-3l1-1z",
    "M1184-19s1 0 0 0l-1 1v0h-1-1c-1 0 0 0 0 0v-2h1l1-1 1 2z",
    "M1205-27v1l-1 2h-2v2h1l-1 2-5 1c0 2 1 1 2 1l3-1c2-1 2-1 3 1v1 1l1-1v-2h3l-2-2 2-2c2-1 2-1 1-2h-1l-2 2v-1 1l-1-5-1-1v2z",
    "M1136-21zM1136-21c-2-2-2-4-1-6l1-4-4 1c-2 2-4 2-6 1l-2-1-1-1-1 2v5l-4 4c-1 2 1 1 1 1l5 1h1l1 6c1 2 2 2 3 0l4-5h1c2-1 4 1 4-1 1-2-1-2-2-3z",
    "M1389 180c1-4 0-7-5-9 5-1 9-2 9-7 2 4 5 6 10 6-4 3-4 6-3 10-2-1-7-6-11 0z",
    "M1404-41c7 0 12-3 14-9l1 1c2 5 5 8 10 8 3 0 2 2 1 3h-1c-4 3-5 7-3 11v2l-1 1c-5-4-11-5-17 0 3-7 2-13-4-17z",
    "M1406 57c-5 5-6 10-3 16-6-3-11-3-15 2 1-7-1-12-8-15 7 0 11-4 12-10 3 6 7 9 14 7z",
    "M1299-19l1-1h4c2 0 3-1 3-3l4-5c3 2 3 5 4 7l4 2c1 1 3 0 4 2l-3 2-2 2c-2 1-1 3-1 4-1 2 1 3-1 4-1 1-2-1-3-1-3-2-5-2-7 0h-4l1-6c1-1 0-3-1-4-1 0-3-1-3-3z",
    "M1270 155c7 1 10-3 12-8 2 1 2 2 2 3 1 3 3 6 7 6 1 0 3 0 1 2-4 6-4 6-4 12-5-4-9-4-15-1 3-6 2-10-3-14z",
    "M1369 24c-2-2-1-5-3-6-2-2-4-2-8-3 6-3 9-7 7-13 5 4 9 5 15 2-1 3-3 6-2 8l4 6c-1 0-6-2-9 0-3 1-4 6-4 6z",
    "M1307 70c3 4 7 6 11 4-2 4-2 8 1 12-4-1-8-1-10 3-1 1-2 1-2-1 0-4-3-6-8-8 6-1 8-4 8-10z",
    "M1337 86c3 5 7 6 12 4-3 5-3 8 0 12-4-1-7 0-10 3 0 2-2 1-1 0 0-5-3-6-8-8 5-2 8-5 7-11z",
    "M1367 114v-1-1h1l1 1h3l1-2h2l-1 2-1 1-1 1c-1 1-2 1 0 2l2 2h-3l-1 1v2c-2 0-2-1-2-2s-1-2-2 0l-1 1h-1v-2h1c1-1 2-2 0-4v-1h2c0 1 0 1 0 0zM1400-11v-1l2-1v2 2h1l2 1 1 1-1 1-2-1-2-1c-1 0-2 0-1 2v2l-2-1v-1h-2c-1 1-1 2-2 1s0-2 1-2c1-1 1-2-1-2l-1-1-1-1h3c1 1 2 1 2-2h1l1 1 1 1z",
    "M1397 146c4-3 6-5 5-8 0-1 1-2 2-1 2 2 5 2 9 0-2 4-2 7 1 10-3 1-7 1-8 4h-2c0-4-3-5-7-5z",
    "M1313 135l-2-2 1-3h3l1 3-3 2z",
    "M1376 140c-2 4-4 7 0 10-4-1-7-1-10 2 0-4-1-6-5-7 3-2 6-3 6-7 2 2 4 5 9 2z",
    "M1330 11c-2 4-4 7 0 10-4-1-7-1-10 2 0-4-1-6-5-7 3-2 6-3 6-7 2 2 4 5 9 2z",
    "M1411 114l-4-1c4-2 6-5 5-9 4 2 7 4 11 2-2 4-2 7 2 10-5 0-8 1-10 5-2-2-1-6-4-7 1-1 2-1 4 1h1l3-2c0-3-3-3-4-4 1 4-3 3-4 5z",
    "M1397 31c1-3 1-5-2-7 3 0 6 0 6-4 2 3 3 5 6 5-2 2-4 3-3 6-2-1-4-2-7 0z",
    "M1371 94c1-3 1-4-1-6 2 0 4 0 5-3 1 2 1 4 4 4-2 1-3 3-2 5-2-1-4-2-6 0z",
    "M1289 107l-1-1 1-2 1 1v1l-1 1z",
    "M1298 40c1 1 0 3 2 4v-4c0-1-1-3 2-3 1 1 3 3 4 1s-2-2-3-3h-1v0l-1-2 4-4c-3-1-4 2-6 2l-1-3c0-2-1-2-1-1l-1 6-3-1-5-1c-1 0-2 0-1 1l1 1 3 1 3 1c0 2-2 2-2 3-2 1-5 2-5 5l7-3c2-2 3-1 4 0zm1-8v-1 1z",
    "M1348-13l6-5c2-1 2-1 3 1 0 2 0 4 3 4v-4c0-1-1-3 1-3l6 2c1 1 2 2 3 0 1-1 0-2-1-2l-1-1-4-1c-1-1 0-1 1-2h1c2-1 4-1 4-4h-2c-1 0-3 2-4 1-1-2 1-3 2-4v-1l1-1-1-3-1 2-5 7c-2 0-1-2-1-3l-1-5c0-2-1-2-2 0v3c-1 7-1 7-8 6l-3-1c-1 0-3 0-3 2 1 1 2 2 3 1l7 2 1 1-6 2-2 1-2 1c-1 1-3 1-2 3l3-1h3c0 1-2 2-1 3s2 0 2-1z",
    "M1344 31l1-1c0-1 3 4 3-1h3c1 0 2 1 3-1l-2-1-2-1c-1 0-3 0-3-3 1-1 2-2 1-3s-2 1-2 2v1l-1-1-2-2-1 2c1 3-1 2-2 3h-2c1 2 2 1 3 1 1 1 3 1 2 3l-1 1v3c2 1 2-1 2-2zm-3-5z",
    "M1324 181c1 1 1 4 3 3 2 0 1-3 1-4v-1c-1-3 0-3 2-3l4 2h2c2 1 3 1 3-1 1-1 0-2-1-2l-4-1-2-1c-4 0-4-1-2-3l1-2c1-2 3-3 2-4-2-1-2 1-3 2-2 0-2 2-3 2v1c-2 1-2 0-2-1l-1-2-2 1c1 5-2 4-5 4l-2-1-2 1 1 2 3 1c3 0 4 1 1 4-2 1-3 2-1 4 1 1 2 0 3-2l1-1c1-1 2 0 3 1v1z",
    "M1237 174c0-2 0-3 2-3 2 2 3 4 5 3 1-2-2-3-3-5l-1-1h-1c0-1-2-1-1-3h6c1-2 1-2-2-3l-2-1 1-1c1-1 3-1 3-3l1-2-4 1-2 2-4 1-1-4-1-1-1 2v1c0 1 1 2-2 3l-2-2-2-1-1 1v2c2 5 1 6-2 7l-1 1c1 4 3 1 5 1h1c1 0 2-2 3-1 2 0 1 2 1 3l1 3 2 2c2 0 2-1 2-2z",
    "M1348 133h-4c-2 0-4 0-3-2v-5c-2-2-1-3 1-3s3 0 5-2c1-2 2-2 2 1l2 3c4 2 4 2 0 5v2c0 3-2 2-3 1z",
    "M1411 114v-2c3-1 3-2 2-5l1-1 1 1c0 2 1 2 2 2 3 0 3 3 4 5 1 1-1 1-2 1-2 0-3 1-4 3 0-3-2-4-4-4z",
    "M1434 148l1-1c2 1 3 4 5 4l1-1-1-1-1-2-2-2-1-1c-1-1-2-3 1-3 0-3-2-1-3-2l1-6-2 5-1-1-2-1-3-2h-2v2l2 3h1c1 2 1 3-1 4-1 1-4 1-5 4 2 3 4 2 6 0 1 0 1-2 2 1 0 1-1 4 2 4 2 0 2-3 2-4z",
    "M1362 57h2l-1-1-1 1z",
    "M1368 69c0-4-7-11-12-11l1-3h1l1-1c1-1 3-1 2-2h-3l-1 1-3-1c0-1 0-4-2-3-2 0 0 2 0 3v2l-5-1-4-1c-2 0-3 1-2 3l4 1c2 1 2 2 1 3l-1 1-3 3c0 1-2 4 1 3l3-2 1-1c2-1 3-2 3 1v2c4 1 3-2 4-4l1-2 7 5c2 0 3 3 6 4zm-11-16z",
    "M1429 177l-2-1v-1h-1l-1-1 1-1c2-2 0-2 0-3l-1-1h1c2 0 2 0 3-2h1l1 1c-1 2 0 2 1 1h1v1l-2 2 1 2 1 1 1 2h-2l-2-2-1 2z",
    "M1427 74c-1 1-2 1-2 3h-1l-3-1v-4l3-1 1 1 2 2zM1440 3c1 1 0 3 2 4-2 0-2 1-3 2s0 0-1 0l-2-2 1-3h1l2-1z",
    "M1454 46l-1-2 3-2 3 2-1 3h-1l-2 1-1-1v-1z",
    "M1453 87v-1-2h1l2-1-2-1-1-2-1-1-1 1-2 1-1 1 1 1v3h3l1 1z",
    "M1428 97s0 1 0 0l-2-1 1-1v-1h2v1l1 1-2 1z",
    "M1454 118h-1l-3-1v-1h1v-1h-2l-1 1-1-1-2-5-1 2 1 3c1 2 1 2-1 3h-1-1l2 1h1v3l2-2 2 2c1 2 2 2 2 1v-1l-1-2-1-1h5l1-2-1 1z",
    "M1430 49zM1430 49c2-2 4-2 6-1h4l-2-3v-6l1-2 1-1-2-1h-5l-4-4c-2-1-1 1-2 1v5h-1l-5 2c-2 1-2 2 0 3l5 4v1c0 2-1 4 1 4 1 1 2-1 3-2z",
    "M1245 202c-1 7 2 10 7 12 0 2-1 2-2 2-4 1-6 3-6 7 0 1 0 3-2 2-6-5-6-5-13-5 5-5 5-9 2-15 5 3 10 2 14-3z",
    "M1270 230c2 0 2-1 2-3 1 1 2 2 4 1v4c-1-1-3 0-3 1l-3-3z",
    "M1314 213l1-1 1 2h1l-1 1h-1l-1-2z",
    "M1292 218c1 0 3 0 2 2s-3 3-2 5c1 1 3-2 4-3h1v-2c2 0 2-2 4-1v6c2 1 2 1 2-2l1-2 1 1 3 3 2 1-1-4-1-2-2-4h1l3-1 2-2h-3c-1 0-3 0-3-2l1-2 2-2-2-1h-2c-5 2-5 1-7-2v-1c-5 0-2 3-2 5v1l2 3c-1 2-3 1-4 1l-3 1-2 2c0 2 2 2 3 2z",
    "M750 396c8-7 10-14 5-22 1-2 2-1 3-1 6 4 12 4 18-1 2-3 3-1 3 1l-1 2c-2 6 2 11 7 14l2 2 1 1c-10 0-16 5-17 15-4-10-10-13-21-11z",
    "M846 486c-1 10 3 15 12 17-9 3-13 8-13 17-5-7-12-10-21-7 7-7 6-14 1-21 9 3 15 2 21-6z",
    "M775 421v-1-3l2 1v1c0 2 1 2 2 1h2l2-2 2 1c1 1 0 1-1 2l-1 1-3 1c-1 1-2 1 0 2h1c1 1 2 2 1 3h-3l-1-1h1l-2 1v2l-1 2c-2 0-2-1-1-3 0-2-1-2-3-1l-1 1-2-1 1-1 1-1c2-1 3-2 1-4v-2l2 1h1z",
    "M730 515c2 5 3 9 9 9-5 3-7 6-6 11-4-4-8-5-12-2 1-5 3-8-2-12 5-1 9 0 11-6z",
    "M801 463c-3-3-4-6-8-5 2-3 4-5 1-9 4 1 7 2 10-1 0 4 0 7 4 9-3 1-6 2-7 6z",
    "M858 544c-2-3-3-5-6-4 1-3 3-4 1-8 3 2 5 2 8-1 0 4 0 6 3 8-3 1-5 1-6 5z",
    "M714 582c2 0 3 3 6 2 0-2-2-3-4-5 0 0-3-1-1-3 2-1 6-1 5-3-1-3-4 0-6 0h-3v-8c-3 2-2 5-4 8l-4-3c-1-1-2-1-1 1l5 6-4 2-6 4v2h2l3-2c2-1 2-3 4-2s0 3 0 4c1 3-2 6 1 10l4-10c0-3 2-3 3-3zm-6-9z",
    "M687 458l1-9c0-3 1-4 3-2 2 1 4 3 7 0 0-2-3-3-4-4s-4-1-2-3l7-4c2 0 4 0 4-3 0-2-2-2-4-1h-1c-2 0-4 1-5 3-2 0-2-2-2-3l2-2c0-2 2-4 0-6l-2 2c-1 1-1 4-3 4-3 0-2-3-3-6l-1-2-3-2 1 3 2 10c-2 2-3 0-4-1l-5-4c-2-1-3-1-2 2l2 3c7 8 7 8-2 13l-3 2c-1 1-2 3-1 4 2 1 3 1 4-1 3-1 5-5 9-5v1l-2 7-1 3-1 3c-1 1-3 3-1 4s3-1 3-3c0-1 0-3 2-3 2 1 1 4 3 4s2-2 2-4z",
    "M881 482c1 1-1 3 1 5 1 0 0 0 0 0l-4 3-1-1c0-2-2-2-3-3l2-4h5zM827 403l5 2h1c-1 1-2 3-1 4v1h-1c-1-1-3 0-4 1 0-2 0-3-2-4v-1c2-1 2-2 2-3z",
    "M880 430h-2v-6c2 1 4 1 5-1l2 4v1c-2 1-2 2-2 3h-1l-2-1z",
    "M918 470l-1-2-1-1v-1l1-3-2 1h-3l-2-1v2c1 1 0 2-1 3v1l2 1 1 1 1 2c1-1 2-3 4-3h1z",
    "M904 503s0 1 0 0l-2 1h-1v-2h-1v-1l2-2 1 2h1v2z",
    "M861 455zM861 455c0-3 1-5 4-6 2 0 3-1 4-3-1-2-3-2-5-2-2-1-4-2-5-5l-1-3-1-2c-1-1-1 0-2 1-2 2-2 4-5 5l-8-1c-2 1 0 2 0 3l4 5v1c-2 2-2 5-3 7s-1 3 2 2c3 0 6-2 9 0l1 1c2 0 3 3 4 3 3-1 1-4 2-6z",
    "M999 449h-2c-2 0-4-1-5-3-3-2-4-1-6 1s-4 3-7 3c-3-4 0-7 1-11l-4-4h-1c0-2-3-2-2-5 1-2 3-1 4-1h4c3 0 3-3 5-4 1-2 1-5 3-5s1 3 3 4c1 4 4 5 8 5 1 1 3 0 5 2l-6 6c-2 1-2 3-1 5l1 7z",
    "M1002 396c5-3 8-5 7-11 4 4 7 6 12 4-2 4-3 8 1 12-5-1-9-1-11 4-2-4-3-9-9-9z",
    "M946 407l-9 2c-3 0-4-1-3-3 1-3 3-5-1-7-3 1-3 3-3 5-1 1 0 3-3 2l-5-6c-1-1-2-4-4-3s-1 3 0 4l1 1c0 3 1 4 3 5l-2 2-2-1c-2 0-5-1-7 1l3 2c1 0 5 0 5 2s-3 3-5 3l-1 1-2 1v3l2-1 10-4c2 1 0 2 0 3l-4 7c0 2 0 3 3 1l2-3c6-8 6-8 13-1l3 3h4c1-2 0-4-2-4l-7-7v-1h1l7 1h6c2 1 4 2 5-1l-4-1c-1 0-3 0-3-2 1-1 4-1 3-3 0-2-2-2-4-1z",
    "M588 386c7-6 10-13 5-21 1-2 2-1 3-1 6 4 12 4 17-2 3-2 4 0 3 2v1c-2 7 2 11 7 15l2 1 1 2c-10 0-16 5-17 15-4-10-11-13-21-12z",
    "M665 471c-1 10 3 15 12 17-9 3-13 8-13 17-5-7-12-10-21-7 7-7 6-14 1-21 9 3 15 2 21-6z",
    "M600 481c-3 0-6-4-9-3-3 0-6 2-10 4 3-8 2-14-5-18 7-1 13-4 15-12 2 4 3 7 6 9 2 2 6 2 9 2 0 0-7 4-8 8-2 5 2 10 2 10z",
    "M639 564c8 1 13 0 15-7 2 7 5 10 12 11-5 3-8 6-6 12 0 2-1 3-2 1-5-4-9-3-15 0 3-7 2-12-4-17z",
    "M596 418v-1-2l2 1v1c0 2 0 2 2 1l1-1 3-1h2l-1 2-2 1-2 1c-1 1-2 2 0 3 1 1 3 1 2 3-1 1-2 0-3-1h-1l-2 1v3c-2 0-2-1-2-2 1-3 0-3-2-2l-2 1h-1v-2l1-1c2-1 4-2 1-4l1-1 1 1 2-1z",
    "M731 566l-5 2c2-6 2-10-3-14 5-1 10-2 12-8 2 5 5 9 11 9-5 3-6 7-4 13-4-1-7-4-11-2 0-3 2-4 4-4 1 0 2 2 2-1-1-1 2-3 0-5s-5 1-7 1c5 2 0 6 1 9z",
    "M633 461c-2-3-4-6-8-5 2-3 5-5 2-10 4 2 7 3 10 0 0 4-1 7 3 8-3 2-6 2-7 7z",
    "M530 465l1-9c0-4 1-4 3-2 2 1 4 3 7 0 0-3-2-3-4-4-1-1-3-1-2-3l7-4c2 0 5-1 4-3 0-2-2-2-3-1h-2c-2 0-3 1-5 3-2-1-1-2-1-3l1-2c1-2 2-4 0-7l-1 2c-2 2-2 5-4 5-2-1-2-4-2-6l-1-3-4-1 2 3 1 10c-1 2-2 0-3-1l-6-4c-2-2-3-1-2 2l3 3c6 7 6 7-2 13l-4 2c-1 1-2 3-1 4 2 1 4 0 4-2 4-1 6-4 9-4h1c-2 2-3 5-3 8l-1 2-1 3c-1 2-2 4 0 5 1 1 2-2 2-3 1-2 1-3 3-3 1 1 0 4 3 4 2 0 2-2 1-4z",
    "M584 511l-1-1c-1-2 7 0 2-4l2-4c2 0 4-1 3-3-1-1-2 0-3 1l-2 1c-2 0-4 3-6 0-1-2-1-4-3-3v3l1 1h-5c-1 1 0 2 1 2 3 2 1 4 0 6l-1 1c2 1 3 0 4-1 1 0 2-2 4 0v2c1 1 1 3 3 3 2-1 1-3 1-4zm-8-1h-1 1z",
    "M731 566l-1-2c1-4 0-6-3-7l-1-2h2c2 2 3 0 4-1 3-2 6 0 9 1 1 1-1 2-1 3-2 2-3 4-1 7-3-2-6-2-8 1z",
    "M833 546l-1-2c3-1 7 1 9-1v-1l-2-1h-3l-4-1 2-3c-3-1-8 3-5-1-3-2-3 2-4 2l-6-7 2 7-3 1-4 1-3 1c0 2 2 2 3 2l4 2v-1h1c3 0 3 1 3 4-1 3-4 5-1 8 5 2 5-1 6-4 0-1-1-4 2-2 2 1 3 4 5 2s-1-4-1-6z",
    "M639 517h2l1-2-2 1-1 1z",
    "M656 523c-4-4-16-3-21 1v1c-2-1-1-3-2-4v-2l1-2c1-1 2-3 0-4l-3 3v2c-1 2-2 3-4 3-1-2-3-4-5-2-1 2 2 3 3 4l1 1h1c-2 2-3 4-5 4l-5 4c-2 2-2 3 1 4 2 0 3-1 5-3 2-1 3 0 3 2v2 6c1 1 2 5 4 1l1-4v-3c0-2 1-4 3-1l3 1c4-2 1-4 0-7l-1-2c3-2 7-3 10-3 4-1 7 1 10-2zm-25-5v0z",
    "M804 486h3c1 1-1 1-1 1l-1 1c-3 1-3 1-1 4 1 1 2 2 1 3s-2-1-3-2l-1-2-1 2-1 4-1 1c-2 0-1-1-1-2-1-1 0-3 1-4v-2l-3 1-1-2c1-1 3 0 3-2l-1-2v-1h-1v-2c1-1 1 0 2 1s2 3 5 2l2 1z",
    "M717 472c0 2-1 4 1 5l-1 1c-1 0-2 0-3 2h-1c0-2-2-3-4-3 2-2 3-3 2-4 0-2 1 0 2-1h4zM663 393c2 0 2 3 4 2h1v4l-1 1c-2-1-3 0-5 1 0-1 0-3-2-4l1-1 2-3z",
    "M716 420h-3c1-2 2-3 0-5 2 0 4 0 6-2 0 2 0 4 2 4l-1 2c-1 0-2 1-2 3h-1l-1-2z",
    "M749 440l-1-1-2-2v-1l1-2h-5l-1-1v2c0 2-1 2-2 3s-1 2 1 2h1l1 1 2 2c1-1 1-3 3-3h2z",
    "M740 493l-1 1h-2l-1-2 1-2 1 1h2v2z",
    "M775 528l-1 1-2 2-2-1c0-1 0-2-1-1l-1 2v2h-3l-6-4c-1 2 0 3 1 3l4 3c4 0 4 0 2 2l-2 2h2l2-1c1 0 1 3 2 2v-3h5l2-1-1-1h-4l4-5v-3l-1 1z",
    "M708 397zM708 397c0-3 2-5 5-6l4-4c-1-2-3-2-5-2-3-1-5-2-5-5h-1v-3l-1-2-2 1c-2 2-3 5-6 5h-7c-3 1 0 2 0 3l3 4 1 1-4 7c-1 3 0 3 2 3 3-1 6-3 10-1v1c2 1 3 4 5 3 3-1 1-3 1-5z",
    "M682 133c-1 4 1 6 5 9-5 1-8 2-9 7-2-4-5-7-9-6 3-3 3-6 2-10 2 1 7 6 11 0z",
    "M669 367c-7 0-12 2-14 9l-1-1c-2-6-5-8-11-9-2 0-2-1 0-2v-1c5-2 5-6 4-11v-2-1c6 5 11 6 18 1-3 7-2 13 4 17z",
    "M667 268c5-4 5-9 2-15 6 3 11 3 16-2-1 6 1 11 7 14-7 1-10 4-11 11-3-6-7-9-14-8z",
    "M774 345l-1 1h-4c-3 0-4 1-4 3s-1 3-3 4c-4-1-3-4-4-7l-4-1h-1c-1-1-3 0-3-2s2-2 2-3l3-1c2-1 1-3 1-5v-4c2-1 3 2 4 2h7c1-1 2-2 4-1l-2 6c0 2 0 3 2 4l3 4z",
    "M787 165c-6-1-10 2-11 7-2 0-2-1-2-2-1-4-3-6-7-6-1 0-3 0-2-2 4-6 4-6 5-13 4 5 9 5 14 2-2 5-1 10 3 14z",
    "M704 301c2 2 1 6 3 7 2 2 4 2 7 3-5 3-8 7-6 13-5-4-9-6-15-2l2-8c0-2-2-4-4-6 0 0 6 2 9 0 3-1 4-7 4-7z",
    "M759 267c-3-4-7-6-12-4 3-4 3-8 0-12 4 1 8 1 10-3 1-1 2-1 2 1-1 4 3 6 7 7-5 2-7 5-7 11z",
    "M736 239c-4-4-7-6-12-4 2-4 2-8-1-12 5 1 8 1 10-3 1-1 2-1 2 1 0 4 3 6 8 8-5 1-8 4-7 10z",
    "M705 212v2h-1v-1c-1-2-1-2-2-1l-1 1-2 1h-1v-1l1-1 2-1v-2h-1c0-1-2-1-1-2h3l1-1v-3l1 2c0 2 1 2 2 1l1-1h2l-1 1v1c-2 1-3 2-1 3v1h-1l-1 1zM673 337l-1 1h-1v-1-2h-2l-2-1-1-1 2-1h1l2 1 2-1v-3l2 2v1h1l1-1 2-1-1 3c-2 1-1 1 0 2h2v1l-1 1-1-1c-1 0-3 0-3 2 1 1 0 1-1 1v-1l-1-1z",
    "M693 180c-3 2-6 4-5 8l-2 1c-2-3-5-2-8 0 1-4 1-8-2-10 3-1 7-1 8-5l2 1c0 3 3 5 7 5z",
    "M759 191l3 2-1 3h-3l-2-3 3-2z",
    "M720 174c2-4 3-6 0-9 4 1 7 1 9-3 0 4 1 7 5 8-3 1-6 2-5 7-3-2-5-5-9-3z",
    "M743 314c2-3 3-6 0-9 4 1 7 1 10-3 0 4 1 7 5 8-4 2-6 3-6 7-3-2-5-5-9-3z",
    "M679 212c2-1 2 0 4 1-4 2-6 4-5 9-4-3-7-4-11-2 2-4 2-7-2-11 5 1 8 0 10-4 2 2 1 6 4 7-1 1-2 0-4-1 0 0 0-2-1 0-1 1-3 0-3 2 0 3 3 2 4 4-1-4 3-3 4-5z",
    "M676 295c-1 3-1 5 1 6-2 1-5 1-6 5-1-3-2-5-5-5 2-2 4-4 3-7 2 1 4 3 7 1z",
    "M702 232c-1 2-1 4 1 5-2 1-4 1-5 4-1-3-2-4-5-4 2-2 4-3 3-5 2 1 3 2 6 0z",
    "M776 234l1 1c1 1 0 1-1 2l-1-1v-1l1-1z",
    "M775 286c-1-1 0-4-2-5l-1 5c0 1 1 3-1 3-1-1-4-3-4-1-1 2 2 2 3 3v-1 1l2 1-5 4c3 1 4-1 6-1h1v3c0 2 1 2 1 0l1-5 4 1 5 1 1-1-2-1-2-1-3-1c-1-2 1-2 2-3s4-2 4-5l-7 3c-1 2-2 1-3 0zm-2 8z",
    "M724 339l-5 4c-2 2-3 2-3-1-1-1 0-4-3-3-2 1-1 2 0 4 0 1 1 2-1 2l-6-1c-1-1-2-2-4-1-1 2 1 2 2 3h1c1 2 2 2 4 2l-1 2h-2c-1 1-3 1-3 4l2-1h4c1 1-1 3-2 4l-1 2 1 3 1-3 4-6c2 0 2 2 2 3v5c1 2 2 2 2 0l1-3c0-7 0-7 8-6l3 1c1 0 2 0 2-2 0-1-1-2-3-1l-7-2v-1l5-2 2-2 2-1c2 0 4 0 3-2l-3 1h-3c0-1 2-3 1-4l-3 2z",
    "M729 294l-1 1v1c-1 1-3-5-3 0-1 2-2 1-3 1s-2-1-3 1l2 1h1c2 1 4 1 3 3v3c1 1 1-1 2-2l1 1 2 2v-2c0-3 2-3 3-3l2-1-3-1c-1-1-3 0-3-2l1-2c1 0 2-2 0-2-1-1-1 1-1 1zm3 5v0z",
    "M734 132c0-2-1-4-3-3-2 0-1 2-1 4l1 1c1 2-1 3-3 2-1 1-2 0-3-1h-3c-1-1-2-1-3 1l2 2 4 1 2 1c3 0 3 1 1 3l-1 2c-1 1-2 3-1 4s2-1 3-3c1 1 1-1 2-1l1-1c1-1 2 0 2 1v2c1 0 3 0 2-1 0-5 3-4 5-4l3 1 2-1c0-2-1-2-2-2l-2-1c-3 0-4-1-1-4 1-1 2-3 1-4-2-1-3 0-3 2l-2 1c-1 1-2 0-2-1l-1-1z",
    "M772 212c0 1-1 3-3 2v1c-1-2-2-4-4-3s2 3 2 4l1 1h1l2 4h-6c-1 2-1 2 2 2l2 1-1 1-3 3c-1 0-2 1-1 2l3-1 3-1 3-2 1 1v3l2 2 1-2v-1c0-1-1-3 1-3l2 1 2 2 2-2-1-2c-1-4-1-5 3-7 0-5-2-2-4-2l-1 1-4 1-1-4-1-3c0-1 0-2-2-2l-1 3z",
    "M734 191l4 1c2-1 4-1 2 2l1 5c2 1 1 2-1 2s-4 0-5 2-2 2-3-1l-1-2c-4-3-4-3 0-5v-3c0-3 2-2 3-1z",
    "M679 212v1c-3 1-3 3-2 6l-1 1-1-1c0-2-1-2-2-2-3 0-4-3-4-5-1-1 1-1 2-1 2 0 3-1 4-3 0 2 2 4 4 4z",
    "M649 161c-3 0-3-3-6-4v2l2 3 2 2c1 2 3 3-1 4 0 2 3 0 3 1l-1 6 3-4 2 1 3 2h2v-2l-2-3c-2-2-2-3 0-4s5-1 5-4c-2-3-4-2-5 0-1 0-2 2-3-1 0-1 1-4-2-4l-2 5z",
    "M711 269l-1-1-2 1 1 1 2-1z",
    "M705 257c0 4 7 10 12 11l-1 3h-1l-2 1-2 2h3l2-1 3 1 1 3c2 0 1-2 1-3v-2l5 1h4c2 0 3-1 2-3l-4-1c-2 0-2-1-1-3h1l3-4c0-1 2-3-2-2l-2 1-2 2c-1 1-2 2-2-1l-1-2c-3-1-3 2-4 4v2l-7-5c-2-1-3-4-6-4zm11 16z",
    "M618 194l-1-1-1-1h2c2 1 2 1 3-2l1-2v5l2-1 2-1h1v1l-3 2-1 1 2 1-1 1h-2l-1 1v1l-1 2-1-2c0-1 1-3-1-4v-1z",
    "M624 153c1-1 1 0 1 1l1 1h2l-1 2h-1c-2 1 0 1 1 2v1l-1 1c-2-1-2-1-3 1l-1 1v-2l-2-1h-1l1-1 2-2c1-1-2-1-2-2l-1-1v-2h2l2 3 1-2z",
    "M646 252c0-1 2-1 2-3l3 1h1v4l-3 1c-1 1-1-1-1-1l-2-2zM629 310c-1-1 0-3-2-4l3-2v-1 1l3 1-1 4h-1l-2 1z",
    "M620 239v3h-1l-2 1 2 1 1 2 1 1v-1l3-1v-1-2-2h-3l-1-1z",
    "M645 229c0-1 0-1 0 0h2l-1 1v1l-2 1v-1l-1-1 2-1z",
    "M636 208h4v2 1l1-1 1-1 2 2 1 5 1-2-1-4c-1-2-1-2 1-2h2l-1-1h-2v-3l-2 2-2-3h-2v1l1 1 1 1-5 1-1 1h1z",
    "M643 277zM643 277c-2 1-4 2-6 1l-4-1 1 4c1 2 2 4 1 5v1l-1 2-1 1h2l5 1 4 4c2 1 1-1 1-2l1-4v-1l6-1c2-1 2-2 0-3s-4-2-5-5v-1l-1-3c-2-1-2 1-3 2z",
    "M848 20c-4-1-7 0-9 5-1-5-2-9-7-9 4-3 6-5 6-10 3 4 6 4 10 3-1 2-6 7 0 11z",
    "M627 5c0-7-3-12-9-14l1-1c5-2 8-5 8-10 0-3 1-2 3-1 3 5 7 6 11 4h2l1 1c-5 5-5 11 0 17-8-3-13-2-17 4z",
    "M725 3c5 5 10 6 15 3-3 6-3 11 3 15-7-1-12 1-15 8 0-8-4-11-11-12 6-3 9-7 8-14z",
    "M649 110l-1-1v-4c0-3-1-3-3-4-2 0-4-1-5-3 2-4 5-3 7-4l2-4v-1c1-1 0-3 2-3l2 2 2 3c1 2 3 1 4 1h4c1 2-1 3-1 4-2 2-2 5 0 7v4l-5-2c-2 0-4 0-4 2l-4 3z",
    "M829 124c1-7-3-10-8-12l2-2c4-1 7-3 7-7 0-1 0-3 2-2 6 4 6 4 12 5-4 4-4 9-1 14-6-2-10-1-14 4z",
    "M692 40c-2 2-5 1-7 3l-3 8c-2-6-6-9-12-7 4-5 5-9 2-15l8 2c3 0 4-2 6-4 0 0-2 6-1 9 2 3 7 4 7 4z",
    "M726 95c5-3 6-7 4-12 5 3 8 3 12 0-1 4 0 8 3 10 2 1 1 2 0 2-5-1-6 3-8 7-2-5-5-7-11-7z",
    "M754 72c5-4 6-7 4-12 5 3 8 3 12 0-1 4 0 7 3 10v1c-5 0-6 3-8 8-2-5-5-8-11-7z",
    "M782 41h-1-1v-1h1v-2-1l-2-1v-2h2l1 1 1 2h2v-1l2-1v4h3l-2 2c-1 0-2 0 0 1v3l-1-1h-1c-1-1-2-3-3-1h-1l1-1-1-1zM656 9l-1-2h4V6l1-3h1v3l-1 2 2 1h2l-1 2h-1v2c1 1 2 1 1 2s-2 0-2-1c-1-1-2-1-2 1l-1 1-1 1v-1-2c1-1 1-2-2-2v-1l1-1V9z",
    "M813 29c-2-3-4-6-8-5v-1c2-3 2-6 0-9 4 1 7 1 10-2 1 4 1 7 4 8v2c-4 0-5 3-6 7z",
    "M803 96l-2 2-3-1v-3l3-1c0 1 0 2 2 3z",
    "M808 33c4 2 6 3 10 0-1 4-1 7 2 10-4 0-6 1-7 5-2-4-3-6-8-6 3-2 5-4 3-9z",
    "M679 79c4 2 6 3 10 0-1 4-1 7 2 10-4 0-6 1-7 5-2-3-3-6-7-6 2-2 4-4 2-9z",
    "M782 15l-1 4c-2-4-5-6-10-5 3-4 5-7 2-11 4 2 8 2 11-2-1 5 1 8 5 10-3 2-6 1-7 4v-4c1 0 2 0 1-1-1 0-1-3-3-3s-2 3-3 4c4-1 3 3 5 4z",
    "M699 12c-3-1-5-1-7 2-1-3-1-6-4-6 3-2 5-3 5-6 2 2 3 4 6 3-1 2-2 4 0 7z",
    "M762 38c-3-1-4-1-6 1-1-2 0-4-3-5 2-1 4-1 4-4 1 2 2 3 5 2-1 2-2 4 0 6z",
    "M760 112l-1 2-2-2 1-1 2 1z",
    "M708 111c1-1 3 0 4-2h-4c-2 0-3 1-3-2 1-1 3-3 1-4s-2 2-3 3v1l-2 1-4-4c-1 2 2 4 2 6l-4 1v1h6l-1 4-1 5 1 1 1-1 1-3c0-1-1-2 1-3l2 2c2 2 2 4 6 5l-4-8c-1-1 0-2 1-3zm-8-2l-1 1 1-1z",
    "M655 60l-5-5c-2-2-1-3 1-3s4 0 4-3c-2-2-3-1-4 0-1 0-3 1-3-1l2-6c1-1 2-2 0-4l-2 2-1 1-1 4c-1 1-2 0-2-1l-1-2c0-1-1-3-3-3v2c0 1 2 3 0 4-1 1-2-1-4-2l-2-1-2 1 2 1 6 5-2 1h-5c-2 1-3 2 0 2l3 1c7 0 7 0 5 8v3l1 2c2 0 2-1 2-3 1-2 0-5 2-7h1c0 2 0 4 2 6l1 1 1 2c1 2 1 4 2 3 2 0 1-2 0-3s-2-2 0-3c1 0 2 2 3 1s0-2-1-3z",
    "M699 65l-1-1c-1-1 4-3-1-3v-3c0-1 1-2-1-3l-1 2-1 2c-1 1 0 3-3 3-1-1-2-2-3-1s2 2 2 2h1l-2 1-1 2 2 1c3-1 2 1 3 2v2c2-1 1-2 1-3 1-1 1-3 3-2l1 1h3c0-2-1-2-2-2zm-5 3z",
    "M847 65c1 0 3-1 3-3-1-2-3-1-5-1v1c-3 1-3-1-3-3l1-4 1-2-1-3c-2 0-2 1-3 2l-1 4v2c-1 3-1 3-4 1l-1-1h-1c-1-1-2-2-4-1-1 1 2 2 3 3l2 2 1 1c1 1 0 2-1 2h-2c-1 1-1 3 1 2 4 0 4 3 3 6v2l1 2 2-2v-2c1-3 2-4 4-1 1 1 3 3 5 1 1-2-1-2-2-3l-2-2c-1-1 0-2 1-2l2-1z",
    "M782 108c-2 0-3 0-3-2v-1c2-1 4-2 3-4-2-1-3 2-5 3l-1 1c-1 0-1 2-3 2v-6c-2-1-2-1-3 2l-1 2-1-1c-1-1-1-3-3-3l-2-1 1 4 2 2 1 3v1h-4l-1 2 2 1h1c1 0 2-1 3 1l-2 2-1 2 1 2h1l1-1c5-1 6-1 7 3l1 1c4-1 1-3 1-5v-1c0-1-2-3-1-4l4-1h2l2-2c0-2-1-2-2-2z",
    "M801 61l-1 4c1 2 1 4-2 3h-5c-1 2-2 1-2-1s0-3-2-5c-2-1-2-2 1-3l3-1c2-4 2-4 5 0h2c3 0 2 2 1 3z",
    "M782 15h-2c-1-3-2-3-5-2l-1-1 1-1c2 0 1-1 2-2 0-3 3-3 4-4 2-1 1 1 1 2 1 2 1 4 4 4-3 0-4 2-4 4z",
    "M816-8h-1c1-3 4-3 4-6h-1-1l-2 2-2 1-1 1c-1 1-3 3-3-1-3 0-1 2-2 3l-6-1 5 3h-1l-1 2-2 3v2h2l3-2v-1c2-1 2-1 4 1s1 5 4 5c3-2 2-4 0-5 0-1-2-2 1-3 1 0 4 1 3-2 1-2-2-1-3-2z",
    "M724 47l1-1-1-1v2z",
    "M737 41c-4 0-11 7-11 12l-3-1-1-1-1-1c0-2 0-3-1-2-2 0-1 2 0 2v5c-2 0-4 0-4 2h6l-1 5-1 4c0 2 1 3 3 2l1-4c1-2 2-2 3-1l1 1 3 3c1 0 3 2 3-1l-2-3-1-1c-2-2-2-3 1-3h2c1-4-2-3-4-4l-2-1 4-7c1-2 4-3 5-6zm-17 11z",
    "M800-46v-1l1-1 1 1-1 1c0 2 0 2 2 3l2 1h-4v2l2 2v1h-2l-1-3-1-1-2 2-1-1v-2l-1-1h-1l-2-1h2l4-1 2-1z",
    "M845-20l-2 2h-1l1 1-1 1c-1 1-1 0-2-1-1-2-1-1-2 0l-1 1-1-2c1-1 1-1-1-2l-1-2h2c1 1 1 0 1-1v-2c1-1 1 1 1 1l2 2 2-2h1l2-1v2l-3 2 3 1z",
    "M741-18l4 2-1 3v1h-4l-1-3c-1-1 0-1 1-1l1-2zM671-31c1-1 3 0 4-2l2 3-2 3-3-1v-1l-1-2z",
    "M713-45l-1 1-3-3c2-1 3-2 2-3l4 1v1l1 2-1 1h-2z",
    "M755-44h-3l-1-1-1-2v2l-2 1-1 1h1l1 3h5v-3l1-1z",
    "M765-19l-1 1v0h-2v-2h1l1-1 1 2z",
    "M785-27v3h-3l1 1v1h1l-1 2-6 1c1 2 2 1 3 1l3-1c2-1 2-1 2 1v1l1 1v-1l1-2h2c1-1-1-1-2-2l3-2v-2l-2 2v-1l-1 1v-5l-2-1v2z",
    "M717-21zM717-21c-2-2-2-4-1-6v-4l-3 1c-2 2-4 2-6 1l-2-1-2-1v7l-4 4c-1 2 1 1 1 1l5 1 2 6c1 2 2 2 3 0l4-5h1c1 0 3 1 4-1s-2-2-2-3z",
    "M970 180c1-4-1-7-5-9 5-1 8-2 9-7 2 4 4 6 9 6-3 3-4 6-2 10h-1c-1-1-6-6-10 0z",
    "M984-41c8 0 13-3 14-9l2 1c1 5 4 8 10 8 3 0 2 2 1 3h-1c-4 3-5 7-4 11v3c-5-4-11-5-17 0 3-7 1-13-5-17z",
    "M987 57c-6 5-6 10-3 16-6-3-11-3-16 2 1-7-1-12-7-15 7 0 11-4 12-10 3 6 7 9 14 7z",
    "M879-19l2-1h4c2 0 3-1 3-3s2-4 4-5c3 2 2 5 4 7l3 2h1c1 1 3 0 3 2 1 1-1 1-2 2l-2 2c-2 1-1 3-2 4v4c-1 1-2-1-4-1-2-2-4-2-7 0h-3l1-6-1-4c-2 0-3-1-4-3z",
    "M851 155c7 1 10-3 12-8l2 3c0 3 3 6 7 6 1 0 3 0 1 2-4 6-4 6-5 12-4-4-8-4-14-1 3-6 2-10-3-14z",
    "M950 24c-2-2-1-5-3-6-2-2-5-2-8-3 6-3 8-7 7-13 4 4 9 5 14 2l-2 8 4 6s-5-2-8 0c-3 1-4 6-4 6z",
    "M887 70c4 4 7 6 12 4-2 4-2 8 1 12-4-1-8-1-10 3-1 1-2 1-2-1 0-4-3-6-8-8 6-1 8-4 7-10z",
    "M918 86c3 5 7 6 11 4-2 5-2 8 1 12-4-1-8 0-10 3-1 2-2 1-2 0 0-5-3-6-8-8 6-2 8-5 8-11z",
    "M948 114v-1-1h1v1h3l2-2h2l-1 2-1 1-2 1c0 1-1 1 1 2l2 2h-3l-1 1v1s1 1-1 1l-1-2c0-1-1-2-2 0l-1 1h-1v-2h1c1-1 2-2 0-4v-1h2zM981-11v-1l1-1 1 1-1 1c-1 1-1 1 1 2h1l2 1 1 1-1 1-2-1-2-1c-1 0-2 0-1 2v2l-2-1v-1h-2c-1 1-1 2-2 1s0-2 1-2c1-1 1-2-1-2l-1-1-1-1h2c2 1 3 1 3-2h1l1 1 1 1z",
    "M978 146c3-3 6-5 5-8 0-1 1-2 2-1 2 2 5 2 8 0-1 4-1 7 2 10-4 1-7 1-8 4h-2c0-4-3-5-7-5z",
    "M894 135c0-1-1-2-3-2 1-1 2-2 1-3h4c-1 1 0 2 1 3l-3 2z",
    "M956 140c-2 4-3 7 0 10-4-1-7-1-9 2 0-4-1-6-5-7 3-2 6-3 5-7 3 2 5 5 9 2z",
    "M910 11c-2 4-3 7 0 10-4-1-7-1-9 2 0-4-1-6-5-7 3-2 6-3 5-7 3 2 5 5 9 2z",
    "M992 114l-4-1c4-2 6-5 5-9 3 2 7 4 11 2-2 4-2 7 1 10-4 0-7 1-9 5-2-2-1-6-4-7 1-1 2-1 4 1h1l3-2c0-3-3-3-4-4 1 4-3 3-4 5z",
    "M978 31c0-3 1-5-2-7 3 0 5 0 6-4 1 3 2 5 6 5-3 2-4 3-3 6-3-1-4-2-7 0z",
    "M952 94c0-3 1-4-1-6 2 0 4 0 4-3 1 2 2 4 5 4-2 1-3 3-2 5-2-1-4-2-6 0z",
    "M870 107l-1-1c-1-1 0-2 1-2l1 1v1l-1 1z",
    "M878 40c2 1 0 3 2 4 2-1 1-3 1-4s-1-3 1-3c2 1 4 3 5 1s-2-2-4-3v0l-2-2 5-4c-3-1-4 2-6 2l-1-3c0-2-1-2-1-1l-1 6-3-1-5-1-2 1 2 1 3 1 2 1c1 2-1 2-2 3s-4 2-4 5l7-3c2-2 3-1 3 0zm2-8v-1 1z",
    "M929-13l6-5c2-2 2-1 3 1 0 2 0 4 3 4v-4c0-1-1-3 1-3l5 2c2 1 3 2 4 0 1-1 0-2-1-2l-1-1-4-1v-2h2c2-1 4-1 4-4h-2c-2 0-3 2-4 1s0-3 1-4l1-1v-1l-1-3-1 2-4 7c-2 0-1-2-1-3l-1-5c0-2-1-2-2 0v3c-1 7-1 7-8 6l-3-1c-1 0-3 0-3 2 0 1 2 2 3 1l7 2v1l-5 2-2 1-2 1c-1 1-3 1-3 3 1 1 2 0 3-1h3v3c1 1 2 0 2-1z",
    "M925 31l1-1c0-1 3 4 3-1h3c1 0 2 1 2-1 1-1 0-1-1-1l-2-1c-1 0-4 0-3-3 1-1 2-2 1-3-2-1-2 1-2 2l-1 1-1-1-1-2-1 2c1 3-1 2-3 3h-1c0 2 2 1 3 1 0 1 2 1 2 3l-1 1v3c1 1 2-1 2-2zm-3-5h-1 1z",
    "M905 181c0 1 1 4 3 3 2 0 1-3 1-4l-1-1c-1-3 1-3 3-3l4 2h2c1 1 3 1 3-1l-2-2-4-1-2-1c-3 0-3-1-1-3l1-2c1-2 3-3 1-4s-2 1-3 2l-2 2-1 1c-1 1-1 0-2-1v-2l-2 1c0 5-3 4-5 4l-3-1-1 1c-1 2 0 2 1 2l2 1c4 0 4 1 1 4-1 1-2 2-1 4 2 1 3 0 3-2l2-1c1-1 2 0 3 1v1z",
    "M818 174c0-2 0-3 2-3 1 2 3 4 4 3 2-2-1-3-2-5l-2-1c0-1-2-1-1-3h6c0-2 0-2-2-3l-3-1 2-1c1-1 2-1 2-3 2 0 2-1 2-2l-4 1-2 2-4 1-1-4-2-1v3c-1 1 0 2-2 3l-2-2-2-1-2 1v1l1 1c1 5 1 6-2 7l-1 1c0 4 3 1 4 1h2c1 0 2-2 3-1 2 0 1 2 1 3l1 3c0 1 0 2 2 2s2-1 2-2z",
    "M929 133h-5c-2 0-3 0-2-2s1-4-1-5c-1-2 0-3 2-3s3 0 4-2 3-2 3 1l2 3c4 2 3 2 0 5l-1 2c0 3-1 2-2 1z",
    "M992 114v-2c3-1 3-2 2-5v-1h1l1 1c0 2 1 2 2 2 3 0 3 3 4 5 1 1-1 1-2 1-2 0-4 1-4 3-1-3-2-4-5-4h1z",
    "M1015 148v-1c3 1 3 4 6 4v-1-1l-2-2-2-2v-1c-1-1-3-3 1-3 0-3-3-1-3-2l1-6-3 5v-1l-2-1-3-2h-2v2l2 3c2 2 2 3 0 4s-5 1-5 4c2 3 3 2 5 0 1 0 2-2 3 1 0 1-1 4 1 4 3 0 2-3 3-4z",
    "M943 57h2l-1-1-1 1z",
    "M949 69c0-4-7-11-12-11-1-1 1-2 1-3h1l1-1c1-1 3-1 2-2h-3l-1 1-3-1c0-1 0-4-2-3-2 0 0 2-1 3l1 1h-1v1l-4-1-5-1c-2 0-3 1-1 3l4 1c2 1 2 2 1 3l-1 1-3 3c0 1-2 4 1 3l2-2 2-1c2-1 3-2 3 1v2c4 1 3-2 4-4l1-2c2 1 5 2 7 5 2 0 3 3 6 4zm-11-16z",
    "M1053 132h1l1 1-1 1h-1c-2-1-2-1-3 1l-1 2v-2-2l-2 1-2 1h-1v-2l3-1 1-1-2-2 1-1 2 1 1-2v-1l1-1 1 1c0 2-1 3 1 4v2z",
    "M1010 177l-2-1v-1h-1l-2-1 1-1h1c2-2 0-2 0-3l-1-1h1c2 0 2 0 3-2h1l1 1c-1 2 0 2 1 1h1v1l-2 2c-1 1 1 2 1 2l1 1v2h-2l-2-2v2z",
    "M1008 74c-1 1-3 1-2 3h-1l-3-1c1-2 0-3-1-4l4-1 1 1 2 2zM1021 3c1 1 0 3 2 4h-1l-2 2h-1l-3-2c1-1 2-2 1-3h1l3-1z",
    "M1035 46l-2-2c2 0 3-1 3-2l4 2c-1 1-2 2-1 3h-1l-3 1v-1-1z",
    "M1034 87v-1-2h1l1-1-1-1-2-2v-1l-1 1-2 1c-1 0-2 0-1 1l1 1v1l-1 2h4l1 1z",
    "M1009 97h-1l-1-1 1-1-1-1h3v2l-1 1z",
    "M1034 118h-1l-2-1v-1-1h-1-1v1l-2-1-1-5c-2 0-1 1-1 2l1 3c1 2 1 2-1 3h-2l1 1h2v3l2-2 2 2c1 2 1 2 2 1v-1l-2-2h1l-1-1h4l2-2-2 1z",
    "M1011 49zM1011 49c2-2 3-2 6-1h3c1-1 0-2-1-3v-6l1-2v-1l-1-1h-6l-3-4c-2-1-1 1-2 1v5h-1l-5 2c-2 1-2 2-1 3 2 1 5 2 6 4v1c0 2-1 4 1 4 1 1 2-1 3-2z",
    "M807 306c3 1 6 0 8-5 1 5 2 8 7 9-3 2-6 5-5 10-3-4-7-4-10-3 0-2 6-7 0-11z",
    "M1037 342c0 7 3 13 9 14l-1 2c-5 1-8 4-8 10 0 3-2 2-3 1v-1c-3-5-7-5-11-4h-3c5-5 5-11 1-17 7 3 12 1 16-5z",
    "M946 331c-5-5-10-6-16-3 3-5 3-10-2-15 7 1 12-1 15-7 0 7 4 10 11 11-6 3-9 7-8 14z",
    "M1021 199l1 1v4c0 3 1 4 3 4s4 2 5 3c-1 4-5 3-7 4l-2 4v1c0 1 1 3-1 3s-2-1-3-2l-2-3c-1-2-3-1-4-1h-4c-1-1 1-2 1-4 2-2 2-4 1-7-1-1-2-2-1-4l6 2c1 0 3 0 4-2l3-3z",
    "M826 202c-1 7 2 10 7 12 0 2-2 2-2 2-4 1-6 3-6 7 0 1-1 3-3 2-5-5-5-5-12-5 4-5 5-9 1-15 6 3 11 2 15-3z",
    "M986 286c2-2 5-1 7-3l3-8c2 6 6 8 12 7-3 4-5 9-1 14-4 0-6-2-8-1-3 0-4 2-6 3 0 0 2-5 0-8s-7-4-7-4z",
    "M928 230c-4 4-6 7-4 12-4-2-8-2-12 1 1-4 1-8-3-10v-2c5 0 7-3 8-8 2 6 5 8 11 7z",
    "M917 262c-5 4-6 7-4 12-5-3-8-3-12 0 1-4 0-7-3-10-2 0-1-1 0-1 5 0 6-3 8-8 2 5 5 8 11 7z",
    "M872 284h3l-1 1v1c-2 0-2 0-1 1l1 1 1 2v2l-2-1-1-1-1-2c-1 0-1-1-2 1l-2 2v-3-2l-1 1-2-1 2-1c2 0 2-1 0-2v-2h1l1 1c1 1 2 2 3 0h1v2zM1022 317h1v1l-1 1v-1c-2-1-2-1-2 1v1l-1 2-1 1-1-1v-2l1-2c0-1 0-2-1-1h-1-2l2-2h1v-2h-1l-1-2c1-1 2 0 3 1s1 1 2-1v-1l1-1 1 1-1 1c0 2 0 3 2 3l1 1-1 1-1 1z",
    "M851 230c1 0 2-1 2-3 1 1 2 2 3 1v4c-1-1-2 0-3 1 0-1 0-2-2-3z",
    "M846 293c-3-2-6-4-9 0 1-4 1-7-3-10 4 0 7-1 8-5 1 3 2 6 7 6-2 2-5 4-3 9zM827 387c-4-2-7-4-10 0 1-4 1-7-2-10 4 0 6-1 7-5 2 4 3 6 7 6-2 3-5 5-2 9z",
    "M983 238c-4-2-7-3-10 0 1-4 1-7-2-9 4 0 6-1 7-5 2 3 3 6 7 5-2 3-5 5-2 9z",
    "M884 311c-1-1 0-2 1-4 2 4 4 7 9 6-3 3-4 6-2 11-4-2-7-2-11 1 1-4 0-8-5-10 3-1 7-1 8-4 1 2 0 3-1 4 0 0-2 0 0 1l2 3c3 0 2-3 4-4-4 2-3-3-5-4z",
    "M979 314c3 0 6 1 7-2 1 3 1 5 5 6-3 1-5 2-5 6-2-2-4-4-7-3 1-2 2-4 0-7z",
    "M909 296c3 1 4 1 6-1 0 2 0 4 3 5-2 1-4 2-4 5-1-2-3-4-5-3 1-2 2-3 0-6z",
    "M895 213l1-1 1 2v1h-1l-1-2z",
    "M946 215c-1 1-3 0-4 2h5c1 0 2-1 2 1-1 2-2 4-1 5 2 1 2-2 3-3v-1l2-1 4 4c1-3-2-4-2-6l4-1v-1l-6-1c1-1 2-2 1-3l1-5c0-1 0-2-1-1l-1 1-1 3c0 1 1 2-1 3l-2-2c-2-2-2-4-6-5l4 7c2 2 0 3-1 4zm9 1z",
    "M1019 259l5 6c1 2 1 2-1 3-2 0-4 0-4 3h4c1 0 3-1 3 1l-2 5c-1 2-2 3 0 4 1 1 2 0 2-1l1-1 1-4c1-1 2 0 2 1v1c1 2 2 4 4 4v-2c0-2-2-3-1-4 2-1 3 1 4 2h1l1 1 3-1-2-1-7-5c0-2 2-1 3-1l5-1c2 0 2-1 0-2h-3c-7-1-7-1-6-8l1-3c0-1 0-2-2-2-1 0-2 1-1 2l-2 8h-1l-2-6-2-2-1-2c0-1 0-3-2-2l1 3v3c-1 0-2-2-4-1l2 2z",
    "M979 261l1 1c2 0-4 3 1 3 2 0 1 2 1 3s-2 2 0 3l2-2v-2c1-1 1-3 3-3 1 1 2 2 3 1s-1-2-2-2h-1l2-1 2-2-3-1c-3 1-2-1-2-2l-1-2-1 3c-1 1 0 3-2 2l-2-1h-2c-1 1 0 2 1 2zm5-3v-1 1z",
    "M808 261c-2 0-4 1-3 3 0 2 2 1 4 0h1c2-1 2 1 2 3l-1 3-1 3 1 3c2 0 3-1 3-2l1-4 1-2c0-3 0-3 3-1h2c1 2 3 3 4 2s-2-2-3-3l-2-2-1-1c0-1 0-2 2-2h2c0-1 0-3-1-2-5 0-4-3-4-6v-2l-1-2-2 2v2c-1 3-2 4-4 1-1-1-3-3-4-1-2 2 0 2 1 3l2 1c1 2 0 2-1 3l-1 1z",
    "M873 218c1 0 2 0 2 2-1 2-4 3-3 5 2 1 3-2 5-3l1-1v-1c1 0 2-2 3-1v6c2 1 2 1 3-2l1-2 1 1c1 1 1 3 3 3l2 1-1-4-2-2-1-4 4-1c1 0 2-1 1-2h-2c-2 0-3 0-4-2l2-2 1-2-1-1h-1-1c-5 2-5 1-7-2l-1-1c-4 0-1 3-1 5v1c0 1 2 2 1 3 0 2-2 1-3 1l-3 1-2 2c0 2 1 2 3 2z",
    "M853 265c1-1 2-3 1-4-1-2-1-4 2-3 2 1 3 1 5-1 1-1 2 0 2 2s0 3 2 4c2 2 2 3 0 3s-3 1-3 2c-3 4-3 4-5 0l-3-1c-3 1-2-1-1-2z",
    "M884 311h1c1 3 3 4 5 3h2l-1 1c-2 0-2 2-2 3-1 3-3 3-5 4l-1-2c0-2-1-4-4-4 3-1 5-2 5-5z",
    "M839 334h1c-1 3-4 3-5 6h2l3-2 2-2c2-1 3-3 4 1 2 0 0-3 1-3l6 1-4-3 1-2 2-3 1-2h-3l-3 2c-1 2-2 1-3 0s-2-5-5-5c-3 1-2 3 0 5 1 1 2 2 0 3-2 0-4-1-4 1s2 2 4 3z",
    "M946 287v3l1-2-1-1z",
    "M934 293c4 0 10-7 11-12l3 1v1l1 2 2 2v-3l-1-2 1-3c1 0 3 0 3-2s-2 0-3 0h-2l1-5v-4c1-2 0-3-2-2l-2 4c0 2-1 2-3 1v-1l-3-3c-1 0-4-2-3 1l2 3 1 1c1 2 2 3-1 3h-2c-1 4 2 3 4 4l2 1c-1 3-2 5-5 7 0 2-3 3-4 6zm17-11h-1 1z",
    "M855 372l-1 1h-1v-1c1-3 1-3-2-3l-2-1 2-1h2v-1l-2-2v-2l2 1 2 3v1l2-2h1v3h1l1 1h2l-2 1-4 1-1 1z",
    "M810 346c-1-2 0-2 1-2l1-1v-2l2 2c1 2 2 0 2 0l1-1 1 1c-1 2-1 2 1 3l1 1-2 1c-1-1-1 0-1 1v1h-1l-2-2-2 1-1 1-2 1 1-2 2-2-2-1z",
    "M930 352l-4-2 1-3v-1h4l1 3c1 1-1 1-1 1l-1 2zM1000 366l-4 1-2-2v-1l2-3 3 1v1l1 3z",
    "M958 379l1-1 2 3-2 3-3-1c0 1-1 0 0 0l-1-3 1-1h2zM860 394l1-1 2 3-2 3-3-1c0 1-1 0 0 0l-1-3 1-1h2z",
    "M916 378h3l1 1v2l1-2 2-1 1-1h-1l-1-3h-1-1-1-2v3l-1 1zM879 380l1 1h3l1 2v-2 1l2-2h1l-1-1-1-3h-1l-1 1h-1l-2-1v4h-1z",
    "M906 353l1-1v-1 1h2v2h-1l-1 1-1-2zM891 399v-1-1h3c1 0 0 0 0 0v2h-1l-1 1-1-1z",
    "M869 353v-1-2h3c1-1 0-1-1-2l-1-1 2-1 5-2h-3l-3 1c-2 1-2 1-2-1v-2l-1 1v2c-1 0-3-2-3 0l2 1-3 3v2h1l1-2h1l1 4 1 1v-1z",
    "M954 355zM954 355c2 2 2 4 1 6l-1 4 4-1c2-1 4-2 6-1l2 1 1 1 1-2v-5l4-4c1-2-1-1-1-1l-5-1h-1l-1-6c-1-2-2-2-3 0s-2 4-5 5c-2 1-4-1-4 1-1 2 1 2 2 3z",
    "M261 146c-1 4 1 6 5 9-5 1-8 2-9 7-2-4-4-7-9-6 3-3 4-6 2-10 2 1 7 6 11 0z",
    "M245 268c5-4 5-9 2-15 6 3 11 3 16-2-1 6 1 11 7 14-7 1-11 4-12 11-2-6-6-9-13-8z",
    "M365 165c-6-1-10 2-12 7l-1-2c-1-4-3-6-8-6-1 0-3 0-1-2 4-6 4-6 4-13 5 5 10 5 15 2-2 5-2 10 3 14z",
    "M282 301c1 2 1 6 3 7 2 2 4 2 7 3-5 3-8 7-7 13-4-4-9-6-14-2l2-8c0-2-3-4-4-6 0 0 5 2 9 0 3-1 4-7 4-7z",
    "M337 267c-4-4-7-6-12-4 3-4 3-8 0-12 4 1 7 1 10-3 0-1 1-1 1 1 0 4 3 6 8 7-5 2-8 5-7 11z",
    "M314 239c-4-4-7-6-12-4 2-4 2-8-1-12 4 1 8 1 10-3 1-1 2-1 2 1 0 4 3 6 8 8-6 1-8 4-7 10z",
    "M283 212v2h-1v-1c-1-2-1-2-2-1l-1 1-2 1h-1v-1l1-1 2-1c0-1 1-2-1-2-1-1-2-1-1-2h2l2-1-1-1 1-2 1 2c0 2 1 2 2 1l1-1h1v1l-1 1c-1 1-2 2 0 3v1h-1l-1 1z",
    "M288 179c-3 3-6 5-5 8 0 1-1 2-2 1-2-2-5-2-8 0 1-4 1-7-2-10 3-1 7-1 8-4h2c0 4 3 5 7 5z",
    "M337 191l3 2-1 3h-4c1-1 0-3-1-3l3-2z",
    "M450 118c2-4 3-7 0-10 4 1 7 1 9-2 0 4 1 6 5 7-3 2-6 3-5 7-3-2-5-5-9-2z",
    "M321 314c2-3 3-6 0-9 4 1 7 1 9-3 0 4 1 7 5 8-3 2-6 3-5 7-3-2-5-5-9-3z",
    "M257 212c1-1 2 0 4 1-4 2-7 4-6 9-3-3-6-4-11-2 2-4 2-7-1-11 4 1 8 0 10-4 2 2 1 6 4 7-1 1-3 0-4-1 0 0 0-2-1 0-1 1-3 0-3 2 0 3 3 2 4 4-2-4 3-3 4-5z",
    "M254 295c-1 3-2 5 1 6-3 1-5 1-6 5-1-3-2-5-6-5 3-2 4-4 3-7 3 1 5 3 8 1z",
    "M279 232c0 2-1 4 2 5-2 1-5 1-5 4-1-3-2-4-5-4 2-2 3-3 2-5 3 1 4 2 6 0z",
    "M354 234l1 1-1 2-1-1v-1l1-1z",
    "M353 286c-2-1-1-4-3-5v5c0 1 1 3-1 3-2-1-4-3-5-1s2 2 4 3v-1 1l2 1-5 4c3 1 4-1 6-1l1 3c0 2 1 2 1 0l1-5 3 1 5 1 2-1-2-1-3-1-2-1c-1-2 1-2 2-3s4-2 4-5l-7 3c-2 2-2 1-3 0zm-2 8z",
    "M306 294v2c-1 1-4-5-3 0-1 2-3 1-4 1s-2-1-2 1c-1 1 1 1 1 1h2c1 1 4 1 3 3 0 1-2 3 0 3 1 1 1-1 2-2l1 1 1 2c2 0 1-2 1-2-1-3 1-3 3-3l1-1-3-1c0-1-2 0-2-2l1-2v-2c-1-1-1 1-2 1zm4 5v0z",
    "M307 147c-1-1-1-4-3-3-2 0-2 3-1 4v1c1 3 0 3-2 3l-4-2h-3c-1-1-2-1-3 1l2 2 4 1 2 1c3 0 3 1 2 3l-1 2c-1 2-3 3-2 4s2-1 3-2l2-2 1-1c1-1 2 0 2 1l1 2 2-1c-1-5 2-4 5-4l2 1 2-1-1-2-3-1c-3 0-4-1-1-4 2-1 3-2 1-4-1-1-2 1-3 2l-1 1c-1 1-2 0-3-1v-1z",
    "M349 212c0 1 0 3-2 2v1c-1-2-3-4-4-3-2 1 1 3 2 4v1h2c0 2 2 2 1 4h-6c0 2 0 2 2 2l3 1-2 1-3 3-1 2 4-1 2-1 4-2v1l1 3 2 2v-2-1c0-1 0-3 2-3l2 1 2 2 2-2-1-2c-1-4-1-5 2-7h1c0-5-3-2-4-2l-2 1-3 1c-2-1-1-3-1-4l-1-3c0-1 0-2-2-2l-2 3z",
    "M303 192c1 2 2 2 4 1s3 0 2 2 0 4 1 5c1 2 1 3-1 2l-5 2c-1 2-2 2-3 0 0-1 0-2-2-3-3-2-3-2 0-5l1-3c0-2 1-1 3-1z",
    "M257 212v1c-3 1-4 3-2 6l-1 1-1-1c0-2-2-2-3-2-2 0-3-3-4-5l2-1c2 0 4-1 5-3 0 2 1 4 4 4z",
    "M222 176h-1c-2 0-3-3-5-4l-1 1 1 1 1 3 2 2h1c1 2 2 3-1 4 0 2 2 0 3 1l-1 7 2-5 3 1 2 2 3 1-1-3-1-3h-1c-2-2-1-2 1-4 1-1 4-1 4-4-1-3-3-2-5 0-1 0-2 2-2-1-1-1 1-4-2-3-2 0-2 2-2 4z",
    "M289 269l-1-1-2 1 1 1 2-1z",
    "M283 257c0 4 6 10 11 11 1 1 0 2-1 3h-1l-1 1c-1 0-3 1-2 2h3l1-1 3 1c0 1 0 3 2 3s1-2 1-3v-2l4 1h5c2 0 3-1 1-3l-3-1c-2 0-3-1-1-3h1l3-4c-1-1 2-3-2-2l-2 1-2 2c-1 1-2 2-2-1l-1-2c-4-1-3 2-4 4v2c-3-1-6-3-7-5-3-1-4-4-6-4zm10 16h0z",
    "M195 194v-1l-1-1h2c2 1 2 1 3-2l1-2v5l1-1 2-1h2v1l-3 2c-1 0-2 0-1 1l2 1-1 1h-3v2h-1v2l-1-2-1-4-1-1z",
    "M226 145c1-1 1 0 2 1 0 0 0 1 0 0h1l1 1c1 1 0 1-1 1v1c-2 1 0 1 0 2l1 1-1 1c-2-1-2-1-3 1h-1l-1-1c1-1 0-1-1-1h-2l1-1 2-2-2-2v-1l-1-2h2l2 3 1-2z",
    "M224 252c0-1 2-1 1-3h1l3 1h0l1 4-3 1c-1 1-1-1-1-1l-2-2z",
    "M197 280l1 2c-2 0-3 0-3 2l-4-2c1-1 2-2 1-3l1-1h3l1 2z",
    "M197 239v3l-2 1 1 1 2 2v1l1-1 3-1v-1l-1-1v-1l1-2h-4l-1-1z",
    "M222 229h2v2l-2 1v-1l-1-1 1-1z",
    "M214 208h3l1 1-1 1v1l2-1 1-1 1 2 2 5 1-2-1-4c-2-2-2-2 0-2h3l-2-1h-2l1-3-2 2-3-3h-2l1 1 1 1 1 1-5 1-1 1h1z",
    "M220 277zM220 277c-1 1-3 2-5 1l-4-1c-1 2 0 3 1 4 1 2 2 4 0 5l1 1-2 2v1h1l6 1 4 4c2 1 1-1 1-2l1-4v-1l6-1v-3c-2-1-5-2-5-5v-1l-1-3c-2-1-2 1-4 2z",
    "M425 20c-3-1-6 0-8 5-1-5-2-9-7-9 3-3 6-5 5-10 3 4 7 4 10 3 0 2-6 7 0 11z",
    "M204 5c1-7-2-12-9-14l2-1c5-2 8-5 8-10 0-3 1-2 2-1h1c3 5 6 6 11 4h2l1 1c-5 5-6 11-1 17-7-3-12-2-17 4z",
    "M303 3c4 5 9 6 15 3-3 6-3 11 2 15-6-1-11 1-14 8 0-8-4-11-11-12 6-3 9-7 8-14z",
    "M227 110l-1-1-1-4c1-3-1-3-3-4-2 0-3-1-4-3 1-4 5-3 7-4l1-4 1-1c0-1-1-3 1-3s2 2 3 2l2 3c1 2 2 1 4 1h4c1 2-2 3-2 4v7c1 1 2 2 1 4l-6-2c-1 0-3 0-4 2l-3 3z",
    "M406 124c1-7-2-10-7-12 0-2 1-2 2-2 4-1 6-3 6-7 0-1 0-3 2-2 6 4 6 4 13 5-4 4-5 9-1 14-6-2-11-1-15 4z",
    "M270 40c-2 2-5 1-7 3l-3 8c-3-6-6-9-12-7 3-5 5-9 1-15l8 2c3 0 4-2 6-4 0 0-2 6 0 9s7 4 7 4z",
    "M304 95c5-3 6-7 4-12 4 3 8 3 12 0-1 4-1 8 3 10v2c-5-1-7 3-8 7-2-5-5-7-11-7z",
    "M332 72c4-4 6-7 4-12 4 3 8 3 12 0-1 4-1 7 3 10v1c-5 0-7 3-8 8-2-5-5-8-11-7z",
    "M360 41h-1-2l1-1c2 0 2-1 1-2l-1-1-1-1v-2h2v1l2 2h1v-1l2-1v3l1 1h3l-2 2c-2 0-2 0-1 1l1 1v2l-1-1h-1c-1-1-2-3-3-1h-1v-1-1zM234 9l-1-2h3l1-1V3h2v3l-1 2 1 1h3l-2 2-1 1 1 1c1 1 2 1 1 2s-2 0-2-1c-2-1-2-1-2 1l-1 1-1 1v-1-2c0-1 1-2-2-2v-1l1-1V9z",
    "M391 29c-2-3-4-6-8-5v-1c2-3 1-6 0-9 4 1 7 1 10-2 0 4 1 7 4 8l-1 2c-3 0-4 3-5 7z",
    "M381 96l-2 2-3-1c0-1 1-2-1-3l4-1c0 1 0 2 2 3z",
    "M386 33c3 2 6 3 9 0-1 4-1 7 3 10-4 0-7 1-8 5-1-4-3-6-7-6 2-2 5-4 3-9z",
    "M257 79c3 2 6 3 9 0-1 4-1 7 3 10-4 0-7 1-8 5-1-3-2-6-7-6 3-2 5-4 3-9z",
    "M360 15l-2 4c-2-4-4-6-9-5 3-4 4-7 2-11 4 2 8 2 11-2-1 5 1 8 5 10-3 2-6 1-7 4-2-1-1-2 0-4 0 0 2 0 0-1-1 0 0-3-2-3-3 0-2 3-3 4 3-1 3 3 5 4z",
    "M277 12c-3-1-6-1-7 2-1-3-1-6-5-6 4-2 6-3 5-6 2 2 4 4 7 3-1 2-2 4 0 7z",
    "M339 38c-2-1-4-1-5 1-1-2-1-4-4-5 3-1 4-1 4-4 2 2 3 3 6 2-1 2-2 4-1 6z",
    "M337 112v2l-2-2 1-1 1 1z",
    "M286 111c1-1 3 0 4-2h-5c-1 0-3 1-3-2 1-1 3-3 2-4-2-1-2 2-3 3v1l-2 1-4-4c-1 2 2 4 2 6l-4 1v1h5v4l-1 5 1 1 1-1v-3l1-3c2 0 2 1 3 2 1 2 2 4 5 5l-3-8c-2-1 0-2 1-3zm-9-2v1-1z",
    "M233 60l-5-5c-2-2-1-3 1-3s4 0 4-3c-2-2-3-1-5 0-1 0-2 1-2-1l1-6c1-1 2-2 1-4l-2 2-1 1c-2 1-2 2-1 4-2 1-2 0-2-1l-1-2c-1-1-1-3-3-3v2c0 1 2 3 0 4-1 1-3-1-4-2l-2-1-2 1 2 1 6 5-2 1h-6c-2 0-2 2 0 2l3 1c8 0 8 0 6 8v3c-1 1 0 2 1 2 2 0 2-1 1-3 2-2 1-5 3-7l3 6 1 1 1 2c0 2 0 4 2 3v-3c-1-1-2-2 0-3 1 0 2 2 3 1s0-2-1-3z",
    "M277 65l-1-1c-2-1 4-3-1-3l-1-3c0-1 2-2 0-3l-2 2v2c-1 1-1 3-3 3-1-1-2-2-3-1s1 2 2 2h1l-2 1-2 2 3 1c3-1 2 1 2 2l1 2 1-3c1-1 1-3 2-2l2 1h3c0-2-1-2-2-2zm-5 3z",
    "M424 65c2 0 4-1 3-3 0-2-2-1-4-1l-1 1c-2 1-2-1-2-3-1-1 0-2 1-4l1-2c0-1 0-3-2-3l-2 2-1 4-1 2c0 3-1 3-3 1l-2-1c-1-1-3-2-4-1s1 2 3 3c-1 1 1 1 1 2l1 1c1 1 0 2-1 2h-2c0 1 0 3 1 2 5 0 4 3 4 6l-1 2 1 2c2 0 2-1 2-2l1-2c0-3 1-4 4-1 1 1 3 3 4 1s0-2-2-3l-1-1c-1-2 0-2 1-3l1-1z",
    "M360 108c-2 0-3 0-3-2v-1c1-1 4-2 3-4-2-1-3 2-5 3l-1 1-3 2v-6c-2-1-2-1-3 2l-1 2-1-1c-1-1-1-3-3-3l-2-1 1 4 2 2 1 3v1h-4c-1 1-2 1-1 2l2 1c2 0 3-1 4 1l-2 2-1 2c-1 1 0 2 1 2l2-1c5-1 5-1 7 3l1 1c4-1 1-3 1-5v-1c0-1-2-3-1-4l3-1h3l2-2c0-2-1-2-2-2z",
    "M379 61c-1 1-2 3-1 4 1 2 1 4-2 3h-5c-2 2-2 1-2-1s0-3-2-5c-2-1-2-2 0-3l3-1c2-4 2-4 5 0h3c3 0 2 2 1 3z",
    "M360 15h-2c-1-3-3-3-5-2l-2-1 1-1c2 0 2-1 2-2 1-3 3-3 5-4 1-1 1 1 1 2 0 2 1 4 4 4-3 0-4 2-4 4z",
    "M393-8c0-3 3-3 4-6h-2l-3 2-2 1v1c-2 1-3 3-4-1-2 0 0 2-1 3l-6-1 4 3-1 2-2 3v2h2l3-2v-1c1-1 2-1 3 1s2 5 5 5c3-2 2-4 0-5-1-1-2-2 0-3 2 0 4 1 4-2 0-2-2-1-4-2z",
    "M302 47l1-1-1-1-1 1 1 1z",
    "M314 41c-4 0-10 7-10 12l-3-1-1-1-1-1c0-2 0-3-2-2v2l1 2v3c-2 0-4 0-4 2h5v5l-1 4c0 2 1 3 3 2l1-4c0-2 1-2 3-1l1 1c0 1 1 3 3 3 1 0 3 2 3-1l-2-3-1-1c-2-2-2-3 0-3h2c2-4-2-3-3-4l-2-1c0-3 2-5 4-7 1-2 4-3 4-6zm-16 11z",
    "M377-46l1-1 1-1v2c-1 2-1 2 2 3l2 1h-3-2l1 2 2 2-1 1h-1l-2-3-1-1-1 2-1-1v-2l-1-1h-1l-2-1 2-1c1 0 3 1 4-1h1z",
    "M422-20c1 1 0 1-1 2h-1l1 1-1 1c-1 1-1 0-2-1-1-2-1-1-2 0l-1 1-1-2c1-1 1-1-1-2l-1-2h2c1 1 1 0 1-1v-2c1-1 1 1 1 1l2 2 2-2h1l2-1-1 2-2 2 2 1z",
    "M319-18l3 2h1c-1 1-2 2-1 3l-1 1h-3l-1-3v-1l2-2zM249-31c1-1 3 0 3-2h1l2 3c-2 1-2 2-2 3l-3-1c-1 1 0 0-1-1v-2z",
    "M291-45l-1 1-3-3 2-3 3 1 1 1v3h-2z",
    "M332-44h-1-2v-1l-1-2-1 2-1 1-1 1h1v3h5v-3l1-1z",
    "M343-19l-1 1v1l-1-1h-1l-1-2h1l1-1 2 2z",
    "M363-27v3h-2-1l1 1v1h1l-2 2-5 1c1 2 2 1 3 1l3-1c2-1 2-1 2 1v2l1-1v-2h3l-2-2 3-2v-2h-1l-1 2v-1l-1 1-1-5-1-1v2z",
    "M294-21zM294-21v-6-4l-4 1c-2 2-3 2-5 1l-2-1-2-1v7l-5 4c-1 2 1 1 2 1l4 1h1l2 6c1 2 1 2 2 0s2-4 5-5h1c1-1 3 1 4-1s-2-2-3-3z",
    "M532 180c1-4-1-7-5-9 5-1 8-2 9-7 2 4 4 6 9 6-4 3-4 6-2 10h-1c-1-1-6-6-10 0z",
    "M562-41c7 0 12-3 14-9l2 1c1 5 4 8 10 8 2 0 2 2 0 3-5 3-5 7-4 11v3c-6-4-11-5-18 0 4-7 2-13-4-17z",
    "M564 57c-5 5-5 10-3 16-5-3-10-3-15 2 1-7-1-12-7-15 7 0 10-4 11-10 3 6 7 9 14 7z",
    "M457-19l2-1h4c2 0 3-1 3-3s1-4 3-5c4 2 3 5 4 7l4 2h1c1 1 3 0 3 2l-2 2-3 2c-2 1-1 3-1 4v4c-2 1-2-1-4-1-2-2-4-2-7 0h-4l2-6c0-1 0-3-2-4l-3-3z",
    "M429 155c6 1 9-3 11-8 2 1 2 2 2 3 1 3 3 6 7 6 2 0 3 0 2 2-4 6-4 6-5 12-4-4-9-4-14-1 2-6 2-10-3-14z",
    "M527 24c-1-2-1-5-3-6-2-2-4-2-7-3 5-3 8-7 6-13 5 4 10 5 15 2l-2 8c0 2 3 4 4 6 0 0-6-2-9 0-3 1-4 6-4 6z",
    "M465 70c4 4 7 6 12 4-3 4-3 8 1 12-5-1-8-1-10 3-1 1-2 1-2-1 0-4-3-6-8-8 6-1 8-4 7-10z",
    "M495 86c4 5 7 6 12 4-2 5-2 8 1 12-5-1-8 0-10 3-1 2-2 1-2 0 0-5-3-6-8-8 6-2 8-5 7-11z",
    "M526 114v-1-1h1v1h3l2-2h1v2l-1 1-2 1v2h1s2 1 1 2h-2-1l-1 1v2l-1-2c0-1-1-2-2 0l-1 1h-1v-2h1c1-1 2-2 0-4v-1h2zM558-11l1-1 1-1 1 1-1 1c-1 1-1 1 1 2h1l2 1 1 1-2 1-1-1-2-1c-1 0-2 0-2 2h1l-1 2-1-1v-1h-3c0 1 0 2-1 1v-2c2-1 1-2 0-2l-1-1-1-1h2c2 1 3 1 3-2h1v1l1 1z",
    "M556 146c3-3 5-5 5-8-1-1 0-2 1-1 3 2 5 2 9 0-2 4-2 7 2 10-4 1-7 1-9 4h-1c0-4-3-5-7-5z",
    "M472 135l-3-2 1-3h3l2 3-3 2z",
    "M534 140c-2 4-3 7 0 10-4-1-7-1-9 2 0-4-2-6-5-7 3-2 6-3 5-7 3 2 5 5 9 2z",
    "M488 11c-2 4-3 7 0 10-4-1-7-1-9 2 0-4-2-6-5-7 3-2 6-3 5-7 3 2 5 5 9 2z",
    "M569 114l-4-1c4-2 7-5 6-9 3 2 7 4 11 2-2 4-2 7 1 10-4 0-8 1-10 5-1-2-1-6-4-7 2-1 3-1 4 1h1l3-2c1-3-2-3-4-4 2 4-3 3-4 5z",
    "M555 31c1-3 1-5-1-7 2 0 5 0 6-4 1 3 2 5 6 5-3 2-4 3-3 6-3-1-5-2-8 0z",
    "M529 94c1-3 1-4-1-6 2 0 4 0 5-3 1 2 2 4 5 4-2 1-4 3-3 5-2-1-3-2-6 0z",
    "M447 107l-1-1 2-2 1 1v1l-2 1z",
    "M456 40c1 1 0 3 2 4l1-4c0-1-1-3 1-3 2 1 4 3 5 1s-3-2-4-3v0l-2-2 5-4c-3-1-4 2-6 2l-1-3c0-2-1-2-1-1l-1 6-3-1-6-1-1 1 2 1 3 1 2 1c1 2-1 2-2 3s-4 2-4 5l7-3c2-2 2-1 3 0zm2-8v-1 1z",
    "M507-13l5-5c2-1 3-1 3 1 1 2 1 4 3 4 2-1 1-3 1-4-1-1-2-3 1-3l5 2c1 1 3 2 4 0 1-1-1-2-2-2l-1-1-4-1h1v-2h2c1-1 3-1 3-4h-2c-1 0-3 2-4 1s1-3 2-4v-1l1-1-1-3-1 2-4 7c-2 0-2-2-2-3v-5c0-2-1-2-2 0l-1 3c0 7 0 7-7 6l-3-1c-2 0-3 0-3 2 0 1 1 2 3 1l7 2v1l-5 2-2 1-2 1c-1 1-3 1-3 3 1 1 2 0 3-1h3c0 1-2 2-1 3s2 0 3-1z",
    "M503 31v-1c1-1 3 4 3-1h4c1 0 2 1 2-1 1-1-1-1-1-1l-2-1c-1 0-4 0-3-3 0-1 2-2 0-3-1-1-1 1-2 2v1l-1-1-1-2c-2 0-1 1-1 2 1 3-1 2-3 3h-1c0 2 1 1 3 1 0 1 2 1 2 3l-1 1v3c1 1 1-1 2-2zm-4-5z",
    "M483 181c0 1 0 4 3 3 2 0 1-3 0-4v-1c-1-3 1-3 2-3l4 2h3c1 1 2 1 3-1l-2-2-4-1-2-1c-3 0-3-1-1-3v-2c2-2 3-3 2-4s-2 1-3 2l-2 2-1 1c-1 1-2 0-2-1v-2c-1 0-3 0-2 1 0 5-3 4-6 4l-2-1-2 1c0 2 1 2 2 2l2 1c3 0 4 1 1 4-1 1-3 2-1 4 2 1 2 0 3-2l2-1c1-1 2 0 2 1l1 1z",
    "M395 174c0-2 1-3 3-3 1 2 3 4 4 3 2-2-2-3-2-5h-1l-1-1c0-1-2-1-1-3h6c0-2 0-2-2-3h-1l-2-1 1-1c1-1 3-1 3-3 1 0 2-1 1-2l-3 1-3 2-3 1-1-4-2-1-1 2v1c0 1 1 2-1 3l-2-2-2-1-2 1v1l1 1c1 5 1 6-3 7v1c0 4 3 1 4 1h1c2 0 3-2 4-1 2 0 1 2 1 3l1 3c0 1 0 2 2 2s1-1 1-2z",
    "M506 133h-4c-2 0-4 0-2-2 1-2 0-4-1-5-1-2-1-3 1-3s4 0 5-2 2-2 3 1l1 3c4 2 4 2 0 5v2c0 3-1 2-3 1z",
    "M569 114l1-2c2-1 3-2 2-5v-1l1 1c0 2 2 2 3 2 3 0 3 3 4 5l-2 1c-2 0-4 1-4 3-1-3-2-4-5-4z",
    "M595 161v-1l6 4v-1-1l-2-2-2-2v-1c-1-1-3-3 1-3 0-3-3-1-3-2l1-6-3 5v-1l-2-1-3-2h-2v2l2 3h1c1 2 1 3-1 4s-5 1-5 4c2 4 4 2 6 1 0-1 1-2 2 0 0 1-1 4 2 4 2 0 1-3 2-4z",
    "M520 57h3l-1-1-2 1z",
    "M526 69c0-4-7-11-12-11l1-3h1l2-1 2-2h-3l-2 1-3-1h1c-1-1-1-4-2-3l-1 3v2l-5-1-4-1c-2 0-3 1-1 3l3 1c2 1 3 2 1 3l-1 1-3 3c0 1-2 4 2 3l2-2 2-1c1-1 2-2 2 1l1 2c3 1 3-2 4-4v-2l7 5c3 0 4 3 6 4zm-10-16h-1 1z",
    "M631 132h1v1l-1 1h-1c-2-1-2-1-2 1l-1 2-1-2v-2l-1 1-2 1h-2l1-2 3-1 1-1-2-2v-1l3 1v-2h1v-1-1l1 1 1 4 1 2z",
    "M571 189c-2 1-2 0-2-1s0-2-1-1h-1c-1-1 0-2 1-2 2-1 0-2 0-3l-1-1h1c2 1 2 1 3-2h1l1 1c-1 2 0 2 1 1h1v1l-2 2 1 2 1 1 1 2h-2l-2-2-1 2z",
    "M585 74c0 1-2 1-2 3l-3-1h-1v-4l3-1c1-1 1 1 1 1l2 2zM599 3l1 4-2 2h-1c0 1 0 0 0 0l-3-2 1-3h1l3-1z",
    "M612 46l-1-2c2 0 3-1 3-2l4 2c-1 1-2 2-1 3h-1l-3 1v-1l-1-1z",
    "M612 87v-1-2h1l1-1-1-1-2-2v-1l-1 1-2 1c-1 0-2 0-1 1l1 1v1l-1 2h4l1 1z",
    "M587 97h-1l-1-1v-1-1h2v1l1 1-1 1z",
    "M612 118h-1l-2-1v-1-1h-2l-1 1-1-1-2-5-1 2 1 3c2 2 2 2 0 3h-2c0-1-1-1 0 0l1 1h2c0 1-2 2 0 3l1-2 3 2c1 2 1 2 2 1l-1-1-1-2-1-1h5l1-2-1 1z",
    "M589 49zM589 49c1-2 3-2 5-1h4c1-1 0-2-1-3-1-2-2-4 0-6h-1l2-2v-1l-1-1h-6l-4-4c-2-1-1 1-1 1l-1 5-6 2v3c2 1 5 2 5 4v1l1 4c2 1 2-1 4-2z",
    "M384 306c4 1 7 0 9-5 1 5 2 8 7 9-4 2-6 5-6 10-3-4-6-4-10-3 1-2 6-7 0-11z",
    "M630 320c-1 8 2 13 9 14 0 2-1 2-2 2-5 1-8 5-8 10 0 3-1 2-3 1v-1c-3-4-7-5-11-4l-2 1-1-1c5-5 5-11 1-17 7 3 12 2 17-5z",
    "M523 331c-4-5-9-6-15-3 3-5 3-10-2-15 6 1 11-1 14-7 1 7 4 10 11 11-6 3-9 7-8 14z",
    "M599 199l1 1v4c0 3 1 4 3 4s4 2 4 3c-1 4-4 3-7 4l-1 4v1c-1 1 0 3-2 3l-2-2-2-3c-1-2-3-1-4-1h-4c-1-1 1-2 1-4 2-2 2-4 0-7l-1-4 6 2c2 0 3 0 4-2l4-3z",
    "M403 202c-1 7 3 10 7 12 0 2-1 2-2 2-3 1-6 3-6 7 0 1 0 3-2 2-6-5-6-5-12-5 4-5 4-9 1-15 6 3 10 2 14-3z",
    "M564 286c2-2 5-1 7-3l3-8c2 6 6 8 12 7-4 4-5 9-2 14-3 0-5-2-8-1l-6 3s2-5 1-8c-2-3-7-4-7-4z",
    "M506 230c-5 4-7 7-4 12-5-2-9-2-13 1 1-4 1-8-3-10-1-1 0-2 1-2 5 0 6-3 8-8 2 6 5 8 11 7z",
    "M494 262c-4 4-6 7-4 12-4-3-8-3-12 0 1-4 1-7-3-10-1 0-1-1 1-1 4 0 6-3 8-8 1 5 4 8 10 7z",
    "M450 284h2v1l-1 1c-1 0-2 0 0 1v1l2 2-1 2-1-1-1-1-1-2c-1 0-1-1-2 1l-2 2v-3-2l-1 1-2-1 2-1c1 0 2-1 0-2v-2h1l1 1c1 1 2 2 3 0h1l-1 1 1 1zM600 317l1 1-1 1-1-1c-1-1-1-1-1 1l-1 1v2l-2 1v-1-2l1-2c0-1 0-2-1-1h-3l2-2 1-2h-1c-1-1-2-1-1-2s2 0 2 1c1 1 2 1 2-1l1-1 1-1v2c0 2-1 3 2 3v1l-1 1v1z",
    "M419 297c2 3 4 6 7 5 2 0 2 1 1 1-2 3-2 6 0 9-4-1-7-2-10 2-1-4-1-7-4-8v-2c4 0 5-3 6-7z",
    "M429 230c1 0 2-1 2-3 1 1 2 2 3 1v4c-1-1-2 0-3 1l-2-3z",
    "M431 272c-3-2-6-3-9 0 1-4 1-7-3-9 4 0 7-1 8-5 2 3 3 6 7 5-2 3-5 5-3 9z",
    "M560 238c-3-2-6-3-9 0 1-4 1-7-3-9 4 0 7-1 8-5 1 3 3 6 7 5-2 3-5 5-3 9z",
    "M450 310l1-4c2 5 5 7 10 6-3 3-5 7-3 11-4-2-7-2-10 1 0-4-1-7-5-9 3-2 6-2 7-5 1 2 1 3-1 4v1c1 1 1 4 3 4s2-3 3-5c-4 2-3-3-5-4z",
    "M557 314c3 0 5 1 7-2 1 3 1 5 4 6-3 1-5 2-4 6-3-2-4-4-7-3 1-2 2-4 0-7zM554 404c3 0 5 1 7-2 1 3 1 5 4 6-3 1-5 2-4 6-3-3-4-4-7-3 1-3 2-5 0-7z",
    "M487 296c2 1 4 1 5-1 1 2 1 4 4 5-3 1-4 2-4 5-2-2-3-4-5-3 1-2 2-3 0-6z",
    "M472 213l1-1 2 2-1 1-2-2z",
    "M524 215c-1 1-3 0-4 2h4c1 0 3-1 3 1-1 2-3 4-1 5s2-2 3-3v-1l2-1 4 4c1-3-2-4-2-6l4-1v-1l-6-1 1-3 1-5c0-1 0-2-1-1l-1 1-1 3-1 3c-2 0-2-2-2-2-2-2-2-4-6-5l4 7c1 2 0 3-1 4zm8 1h1-1z",
    "M600 261l4 5c2 2 2 3 0 3-2 1-5 0-4 3 1 2 3 1 4 0 1 0 2-1 3 1l-2 6c-1 1-2 3 0 4 1 1 1-1 2-2v-1c2-1 2-2 2-3h2v2c1 1 1 3 4 3v-1c-1-2-2-4-1-5s3 1 4 2h1l1 1 3-1-3-1-6-4c0-2 2-2 3-2h5c2 0 2-1 0-2l-3-1c-8 0-8 0-6-7l1-3c0-1 0-3-2-3-1 0-2 2-1 3l-2 7h-1l-2-5-2-2-1-2c0-1 0-3-2-3-1 1 0 2 1 3v3c-1 0-2-2-4-1l2 3z",
    "M557 261l1 1c2 0-4 3 1 3l1 3c-1 1-2 2 0 3l1-2 1-2c1-1 0-3 3-3 1 1 2 2 3 1s-1-2-2-2h-1l2-1 1-2-2-1c-3 1-2-1-3-2v-2c-2 1-1 2-1 3-1 1-1 3-3 2l-1-1h-3l2 2zm5-3v-1 1z",
    "M385 261c-1 0-3 1-3 3 1 2 3 1 4 0h1c3-1 3 1 3 3l-1 3-1 3 1 3c2 0 2-1 3-2l1-4v-2c1-3 1-3 4-1h2c1 2 2 3 4 2s-2-2-3-3l-2-2-1-1c-1-1 0-2 1-2h2c1-1 1-3-1-2-4 0-4-3-3-6v-2l-1-2-2 2v2c-1 3-2 4-4 1-1-1-3-3-5-1-1 2 1 2 2 3l2 1c1 2 0 2-1 3l-2 1z",
    "M444 205c2 0 3 0 2 2l1 1c-2 1-4 2-3 4 1 1 3-2 4-3h1v-1l4-2v6c2 1 2 1 2-2l1-2 2 1 2 3 2 1c1-1 0-3-1-4l-1-2-2-4h1l4-1 1-1-2-1h-1c-1 0-3 1-3-1l1-3 2-1-2-2-2 1c-4 1-5 1-7-3v-1c-5 1-2 3-2 5l1 1 1 4h-4l-3 1-2 2c0 2 2 2 3 2z",
    "M444 252v-4c0-2 0-4 2-3h5c2-2 3-1 3 1s0 3 2 4c2 2 2 3-1 3l-3 2c-2 4-2 4-5 0h-3c-2 0-1-2 0-3z",
    "M450 310l2 1c1 2 2 3 5 2h1v1l-1 1c-2-1-2 1-2 2 0 3-3 3-5 4l-1-2c0-2 0-4-3-4 3-1 4-2 4-5z",
    "M416 334h1c-1 3-4 3-4 6h2l2-2 2-2h1c1-1 3-3 3 1 3 0 1-3 2-3l6 1-5-3h1l1-2 2-3v-2h-2l-3 2c-2 2-3 2-4 0s-1-5-4-5c-3 1-2 3 0 5 0 1 2 2-1 3-1 0-4-1-4 1s3 2 4 3z",
    "M524 287l-1 1 1 2 1-2-1-1z",
    "M512 293c4 0 10-7 11-12l3 1v1l1 2 2 2v-3l-1-2 1-3c1 0 3 0 3-2s-2 0-3 0h-2l1-5v-4c0-2-1-3-3-2l-1 4c0 2-1 2-3 1v-1l-3-3c-2 0-4-2-3 1l1 3 2 2c1 1 2 2-1 2h-2c-1 4 2 3 4 4l2 1-5 7c-1 2-4 3-4 6zm16-11z",
    "M433 372l-1 1h-1l-1-1h1c0-3 0-3-2-3l-2-1 2-1h2v-1l-2-2v-2l2 1 1 3 1 1 2-2h1l-1 3h2v1h3l-2 1-4 1-1 1z",
    "M387 346l1-2h1v-1l1-2 1 2c2 2 2 0 3 0l1-1v1c0 2 0 2 2 3v1l-1 1c-2-1-2 0-1 1v1h-1l-2-2-2 1-1 1-2 1v-2l3-2-3-1z",
    "M507 352c-1 0-1-2-3-2l1-3v-1h4l1 3c1 1-1 1-1 1l-2 2zM577 366l-3 1h-1c0-1 0-2-2-2v-1c2-1 2-2 2-3l4 1v4z",
    "M535 379l2-1c0 1 0 3 2 3l-2 3-3-1h-1v-3-1h2z",
    "M494 378h3v1l1 2 1-2 2-1 1-1h-1l-1-3h-2-3v3l-1 1z",
    "M484 353c-1 0-1 0 0 0v-1-1l1 1h2v2h-1l-1 1-1-2z",
    "M447 353v-1-2h3l-1-2-1-1 1-1 6-2h-3l-3 1c-2 1-2 1-2-1v-2h-1v1l-1 2c-1 0-2-2-2 0l2 1-3 3v2l2-2h1v4l2 1v-1z",
    "M532 355zM532 355l1 6-1 4 4-1c2-1 3-2 5-1h1l1 1 2 1v-2c0-2-1-3 1-5l4-4c1-2-1-1-2-1l-4-1h-1l-2-6c-1-2-1-2-2 0s-2 4-5 5h-1c-1 1-3-1-4 1 0 2 2 2 3 3z",
    "M4 20c-4-1-7 0-9 5-1-5-2-9-7-9 4-3 6-5 6-10 3 4 6 4 10 3-1 2-6 7 0 11zM3 65c1 0 4-1 3-3s-3-1-4-1l-1 1c-3 1-3-1-3-3l2-4v-2l-1-3-3 2v4l-1 2c-1 3-1 3-4 1l-1-1h-1c-1-1-2-2-3-1s1 2 2 3l2 2 1 1c1 1 0 2-1 2h-2c0 1 0 3 1 2 5 0 4 3 3 6v2l1 2 2-2 1-2c0-3 1-4 3-1 2 1 3 3 5 1 1-2-1-2-2-3l-1-1v-3l2-1z",
    "M1-20l-1 2h-1s-1 0 0 0v1l-1 1-1-1c-2-2-2-1-3 0l-1 1v-2l-2-2v-2h1c2 1 2 0 1-1v-2l1 1 2 2 3-2 2-1v2l-2 2 2 1z",
    "M126 180c1-4 0-7-5-9 5-1 8-2 9-7 2 4 5 6 10 6-4 3-4 6-3 10-2-1-7-6-11 0z",
    "M140-41c8 0 13-3 14-9l2 1c1 5 5 8 10 8 3 0 2 2 1 3h-1c-4 3-5 7-4 11l1 2-1 1c-5-4-11-5-17 0 3-7 2-13-5-17z",
    "M143 57c-5 5-6 10-3 16-6-3-11-3-15 2 1-7-1-12-8-15 7 0 11-4 12-10 3 6 7 9 14 7z",
    "M36-19l1-1h4c2 0 3-1 3-3l4-5c3 2 3 5 4 7l4 2c1 1 3 0 4 2l-3 2-2 2c-2 1-1 3-1 4-1 2 1 3-1 4-1 1-2-1-3-1-3-2-5-2-7 0h-4l1-6c1-1 0-3-1-4-1 0-3-1-3-3z",
    "M106 24c-2-2-1-5-3-6-2-2-5-2-8-3 6-3 8-7 7-13 4 4 9 5 15 2-1 3-3 6-2 8l3 6s-5-2-8 0c-3 1-4 6-4 6z",
    "M44 70c3 4 7 6 12 4-3 4-3 8 0 12-4-1-8-1-10 3-1 1-2 1-2-1 0-4-3-6-8-8 6-1 8-4 8-10z",
    "M74 86c3 5 7 6 12 4-3 5-3 8 0 12-4-1-8 0-10 3-1 2-2 1-2 0 1-5-3-6-7-8 5-2 7-5 7-11z",
    "M104 114v-1-1h1l1 1h3l1-2h2l-1 2-1 1-2 1c0 1-1 1 1 2l2 2h-3l-1 1v2c-2 0-2-1-2-2s-1-2-2 0l-1 1h-1v-2h1c1-1 2-2 0-4v-1h2c0 1 0 1 0 0zM137-11v-1l2-1v2 2h1l2 1 1 1-1 1-2-1-2-1-1 2v2c-1 1-2-1-2-1v-1h-2c-1 1-1 2-2 1s0-2 1-2c1-1 1-2-1-2l-1-1v-1h2c1 1 3 1 2-2h2v1l1 1z",
    "M134 146c4-3 6-5 5-8 0-1 1-2 2-1 2 2 5 2 9 0-2 4-2 7 1 10-3 1-7 1-8 4h-1c-1-4-4-5-8-5z",
    "M113 140c-2 4-4 7 0 10-4-1-7-1-10 2 0-4-1-6-5-7 3-2 6-3 5-7 3 2 5 5 10 2z",
    "M67 11c-2 4-4 7 0 10-4-1-7-1-10 2 0-4-1-6-5-7 3-2 6-3 6-7 2 2 4 5 9 2z",
    "M148 114l-4-1c4-2 6-5 5-9 4 2 7 4 11 2-2 4-2 7 2 10-5 0-8 1-10 5-2-2-1-6-4-7 1-1 2-1 4 1h1l3-2c0-3-3-3-4-4 1 4-3 3-4 5z",
    "M134 31c1-3 1-5-2-7 3 0 6 0 6-4 2 3 3 5 6 5-2 2-4 3-3 6-2-1-4-2-7 0z",
    "M108 94c0-3 1-4-1-6 2 0 4 0 5-3 1 2 1 4 4 4-2 1-3 3-2 5-2-1-4-2-6 0z",
    "M35 40c1 1 0 3 2 4v-4c0-1-1-3 2-3 1 1 3 3 4 1s-2-2-4-3v0l-1-2 4-4c-3-1-4 2-6 2l-1-3c0-2-1-2-1-1l-1 6-3-1-5-1c-1 0-2 0-1 1l1 1 3 1 3 1c0 2-2 2-2 3-2 1-5 2-5 5l7-3c2-2 3-1 4 0zm1-8v-1 1z",
    "M85-13l6-5c2-2 2-1 3 1 0 2 0 4 3 4v-4c0-1-1-3 1-3l6 2c1 1 2 2 3 0 1-1 0-2-1-2l-1-1-4-1c-1-1 0-1 1-2h1c2-1 4-1 4-4h-2c-1 0-3 2-4 1-1-2 1-3 2-4v-1l1-1-1-3-1 2-5 7-1-3-1-5c-1-2-1-2-2 0v3c-1 7-1 7-8 6l-3-1c-1 0-2 0-2 2 0 1 1 2 2 1l8 2v1l-6 2-2 1-2 1c-1 1-3 1-3 3 1 1 2 0 3-1h3v3c1 1 2 0 2-1z",
    "M81 31l1-1c0-1 3 4 3-1h3c1 0 2 1 3-1l-2-1-2-1c-1 0-3 0-3-3 1-1 2-2 1-3s-2 1-2 2v1l-2-1-1-2-1 2c1 3-1 2-2 3h-2c0 2 2 1 3 1 1 1 2 1 2 3l-1 1v3c2 1 2-1 2-2zm-3-5z",
    "M85 133h-4c-2 0-4 0-3-2v-5c-2-2-1-3 1-3s3 0 4-2c2-2 3-2 3 1l2 3c4 2 4 2 0 5v2c0 3-2 2-3 1z",
    "M148 114v-2c3-1 3-2 2-5l1-1 1 1c0 2 1 2 2 2 3 0 3 3 4 5 1 1-1 1-2 1-2 0-4 1-4 3 0-3-2-4-4-4z",
    "M171 148v-1c3 1 3 4 6 4v-1-1l-2-2-1-2-1-1c-1-1-2-3 1-3 0-3-2-1-3-2l1-6-2 5-1-1-2-1-3-2h-2v2l2 3h1c1 2 1 3-1 4s-4 1-5 4c2 3 4 2 6 0 1 0 1-2 2 1 0 1-1 4 2 4 2 0 1-3 2-4z",
    "M99 57h2l-1-1-1 1z",
    "M105 69c0-4-7-11-12-11l1-3h1l1-1c1-1 3-1 2-2h-3l-1 1-3-1c0-1 0-4-2-3s0 2-1 3v2l-4-1-5-1c-2 0-3 1-1 3l4 1c2 1 2 2 0 3v1l-4 3c1 1-1 4 2 3l2-2 2-1c2-1 3-2 3 1v2c4 1 3-2 4-4l1-2c2 1 5 2 7 5 2 0 3 3 6 4zM94 53z",
    "M209 132h1l1 1-1 1h-1c-2-1-2-1-3 1l-1 2v-2-2l-1 1-3 1h-1v-2l3-1 1-1-2-2 1-1 3 1v-2-1l1-1 1 1c0 2-1 3 1 4v2z",
    "M166 177l-2-1v-1h-1l-2-1 2-1c2-2 0-2 0-3l-1-1h1c2 0 2 0 3-2h1l1 1c-1 2 0 2 1 1h1v1l-2 2 1 2 1 1v2h-1l-3-2v2z",
    "M164 74c-1 1-2 1-2 3h-1l-3-1v-4l3-1c1-1 1 1 1 1l2 2zM177 3c1 1 0 3 2 4l-3 2c0 1 0 0 0 0l-3-2 1-3h1l2-1z",
    "M191 46l-1-2 3-2c1 1 1 2 3 2l-1 3h-1l-2 1-1-1v-1z",
    "M190 87v-1-2h1l1-1-1-1-1-2-1-1-1 1-2 1-1 1 1 1v1l-1 2h4l1 1z",
    "M165 97s0 1 0 0l-2-1 1-1v-1h2v2l-1 1z",
    "M191 118h-2l-2-1v-1h1l-1-1h-1l-1 1-2-1-1-5-1 2 1 3c1 2 1 2-1 3h-1-1l2 1h1v3l2-2 2 2c1 2 2 2 2 1v-1l-1-2-1-1h5l1-2-1 1z",
    "M167 49zM167 49c2-2 4-2 6-1h3c1-1 0-2-1-3v-6l1-2 1-1-2-1h-5l-4-4c-2-1-1 1-2 1v5h-1l-5 2c-2 1-2 2 0 3l5 4v1c0 2-1 4 1 4 1 1 2-1 3-2z",
    "M178 199v1l1 4c-1 3 1 4 3 4s3 2 4 3c-1 4-5 3-7 4l-1 4v1c-1 1 0 3-2 3s-2-1-3-2l-1-3c-2-2-3-1-5-1h-4c-1-1 2-2 2-4v-7c-1-1-2-2-1-4l6 2c1 0 3 0 4-2l4-3z",
    "M188 257l5 6c1 1 1 2-1 2-2 1-4 1-4 4h4c1-1 2-2 3 1l-2 5c-1 1-2 3 0 4 1 1 2-1 2-2l1-1 1-3h2v2l4 4v-2c0-2-2-4-1-5l4 2h1l1 1 3-1-2-1-6-4c0-2 1-2 2-2h5c1-1 2-1 0-2h-3c-7-1-7-1-5-8v-3l-1-3c-2 0-2 2-2 3l-2 7h-1l-2-5-1-2-1-2c-1-1-1-3-3-3-1 1 0 2 1 3s2 2 0 3c-1 0-2-2-3 0l1 2z",
];